<template>
 <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> Video Transcriber <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
    
    <b-row class="justify-content-center">
        <b-col xs="12" md="4" class="p-2">
          <b-row no-gutters>
            <b-col>
                <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Input-files (required) <b-badge v-b-tooltip.bottom title="Upload a video from your computer, you can trim it by using the two orange sliders, the output video will contain only the selected duration (High quality input video produce high quality output). Switch to images to generate a video from animated Images. Check the tutorial for more details" class="mb-0 text-secondary" pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
                  </template>

                  <div>
                    <label for="format" class="text-secondary">Generate video from</label>
                    <b-form-group id="format" v-slot="{ byinputType }">

                      <b-form-radio class="d-inline-block mr-3" v-model="inputType" @input="onInputType" :aria-describedby="byinputType" name="format-radios" value="video">Video</b-form-radio>

                      <b-form-radio class="d-inline-block" v-model="inputType" @input="onInputType" :aria-describedby="byinputType" name="format-radios" value="images">Images</b-form-radio>

                    </b-form-group>
                  </div>
                  

                  <div v-show="inputType=='video'">
                    <div class="justify-content-center">
                      <videoplayer></videoplayer>
                    </div>
                   
                    <div id="trimmerdiv">
                      <videotrimmer 
                      :frames="allframes"
                      :videoDuration="duration"
                      :defaultTrim="{ 'start':0, 'end':endDefaultDuration }"
                      :inputTypeTrim="inputType"
                      v-on:trim-start="trimstartPlay($event)"
                      v-on:trim-end="trimendPlay($event)"
                      
                      >
                      </videotrimmer>
                    </div>

                    <b-alert v-if="message" variant="danger" dismissible show><p>{{ message }}</p></b-alert>

                    <div class="mt-2 mb-2">
                    <!-- Styled -->

                <!-- ERRORS -->
                      <div class="alert alert-danger" v-show="errors.any('submitvideo')">
                        <div v-if="errors.has('submitvideo.video')">
                          <p>{{ errors.first('submitvideo.video') }}</p>
                        </div>
                      </div>

                      <b-form class="mb-2" data-vv-scope="submitvideo">
                        <b-form-file
                        id="videofileInput"
                        v-model="file"
                        v-validate="'size:210000|ext:mp4'"
                        data-vv-as="video"
                        :state="Boolean(file)"
                        placeholder="Upload MP4 video"
                        drop-placeholder="Drop file here..."
                        name = "video"
                        @input="videoInput"
                        
                      ></b-form-file>
                      </b-form>
                      <b-button size="sm" @click.prevent="removeFile" :disabled="!file">Remove Video</b-button>

                    </div>
                  </div>
                  <div  v-show="inputType=='images'">
                    <div class="mt-2 mb-2 text-secondary">
                      <b-form-group label="Video duration"  v-slot="{ imgVideoDurationby }">
                        <b-form-radio-group
                          id="imgVideoDuration-group-1"
                          v-model="imgVideoDuration"
                          :options="imgVideoDurationOptions"
                          :aria-describedby="imgVideoDurationby"
                          name="imgVideoDuration-options"
                          @input="updateMaxTokens"
                        ></b-form-radio-group>
                      </b-form-group>
                    </div>

                    <b-alert v-if="imagesMessage" variant="danger"  show>{{ imagesMessage }}</b-alert>
                    <div class="alert alert-danger" v-show="errors.any('submitimages')">
                        <div v-if="errors.has('submitimages.images')">
                          <p>{{ errors.first('submitimages.images') }}</p>
                        </div>
                      </div>
                    <b-form class="mb-2" data-vv-scope="submitimages">
                        <b-form-file
                        multiple
                        id="imagesInput"
                        v-model="images"
                        v-validate="'size:3146|ext:png,jpeg,jpg'"
                        data-vv-as="images"
                        :state="Boolean(images)"
                        placeholder="Upload images"
                        drop-placeholder="Drop images here..."
                        name = "images"
                        @input="imagesInput"
                        
                      ></b-form-file>
                    </b-form>
                    
                   

                    <div v-show="imageSelected!==null" style="width:100%; padding-bottom: 3px;" >
                      <b-button size="sm" @click.prevent="onUpClicked"><b-icon icon="chevron-up" variant="light" font-scale="0.8" aria-hidden="true"> </b-icon></b-button>
                      <b-button size="sm" @click.prevent="onDownClicked" ><b-icon icon="chevron-down" variant="light" font-scale="0.8" aria-hidden="true"> </b-icon></b-button>
                      <b-button size="sm" variant="danger" @click.prevent="onRemoveClicked" class="float-right" ><b-icon icon="trash" variant="light" font-scale="0.8" aria-hidden="true"> </b-icon></b-button>  
                    </div>
                    <div  id="imagescontainer" style="width:100%;"></div>
                    <b-button size="sm" @click.prevent="removeImgAll" :disabled="images.length==0">Remove All</b-button>
                  </div>

                </b-card>

              
                <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Input-Watermark (optional) <b-badge v-b-tooltip.bottom title="Upload your logo or brand Image (Supported formats: png, jpg, jpeg, gif / Max-size: 1MB), the logo will be auto added as transparent watermark on top center of your videos." pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
                  </template>


                  <b-alert v-if="videoPackID == '100' || subStatus=='trialing'" variant="warning" show><p><b>Warning!</b> Watermark is not available for the Micro Pack subscription plan or during a trial period for all plans.<b-link class="" tag="li" to="/Transcriber_Billing"> Upgrade</b-link> to Medium or Golden pack subscription or wait until your trial period ends.</p></b-alert>
                  <b-alert v-if="picmessage" variant="danger" show><p>{{ picmessage }}</p></b-alert>

                  <div class="mt-2 mb-2">
                  <!-- Styled -->

              <!-- ERRORS -->
                    <div class="alert alert-danger" v-show="errors.any('submitpicture')">
                      <div v-if="errors.has('submitpicture.picture')">
                        <p>{{ errors.first('submitpicture.picture') }}</p>
                      </div>
                    </div>

                    <b-form v-if="videoPackID !== '100' && subStatus !== 'trialing' " class="mb-2" data-vv-scope="submitpicture">
                      <b-form-file
                      id="picturefileInput"
                      v-model="picture"
                      v-validate="'size:1024|ext:png,jpg,jpeg,gif'"
                      data-vv-as="picture"
                      :state="Boolean(picture)"
                      placeholder = "Upload Image"
                      drop-placeholder="Drop file here..."
                      name = "picture"
                      @input="pictureInput"
                      
                    ></b-form-file>
                    </b-form>
                    <b-button v-if="videoPackID !== '100' && subStatus !== 'trialing' " size="sm" @click.prevent="removePicture" :disabled="!picture">Remove Image</b-button>
                  </div>

                </b-card>
            </b-col>
            
          </b-row>
        
        </b-col>

        <b-col xs="12" md="8" class="p-2" >
              <b-card header-tag="header" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Ai Voice-over <b-badge v-b-tooltip.bottom title="By Enabling Ai voice-over the original video audio will not be used, it will be replaced by the genereted Voice-over and the background music if added. In case of generation from images this feature is required." class="mb-0 text-secondary" pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
                  </template>
                  <div class="mt-2 mb-2">
                    <div class="text-secondary">
                      <b-form-checkbox v-model="aiScriptEnabled" @input="onAiScriptCheck"  name="check-button" switch>
                        Enable Ai Voice-over
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="mt-2 mb-2">
                    <div class="text-secondary">
                      <b-form-checkbox :disabled="!aiScriptEnabled" v-model="aiScriptGeneration"  name="ai-check-button" switch>
                        Generate Ai Script 
                       <b-badge v-b-tooltip.bottom title="You can generate your script with Ai from a prompt describing your video subject. Or just write/paste your text. Modify your script to Ajust the Estimated Voiceover Duration to your video duration" class="mb-0 text-secondary" pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></b-form-checkbox>
                    </div>
                  </div>
                  <div v-show="aiScriptGeneration">
                    <b-alert v-if="promptMessage" variant="danger"  show>{{ promptMessage }}</b-alert>
                    <b-form-textarea
                      class="border-secondary ml-0"
                      id="inputprompt"
                      v-model="prompt"
                      size="sm"
                      placeholder="Enter your prompt to generate your script with Ai"
                      rows="3"
                      maxlength ="500"
                      @input="onPromptInput"
                      :disabled="!aiScriptEnabled || aiScriptSpinner[0]"
                    ></b-form-textarea>
                    <p id="textCounter" class="mb-2 text-secondary"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon>Prompt Characters: {{ prompt.length }} / 500</p>
                    <b-button 
                          class="w-100 mb-2" 
                          size="sm"
                          squared
                          id="script"
                          variant="secondary"
                          @click.prevent="generateScript"
                          :disabled="!aiScriptEnabled || prompt.length<10 || aiScriptSpinner[0]"
                          ><span v-show="aiScriptSpinner[0]" class="spinner-border spinner-border-sm"></span> 
                    Generate Script</b-button>
                  </div>
                  <!--/card-body-->
                  <b-alert v-if="aiScriptSpinner[1]" :variant="aiScriptSpinner[2]"  show>{{ aiScriptSpinner[1] }}</b-alert>
                  <b-alert v-if="promptMessageOutput" variant="danger"  show>{{ promptMessageOutput }}</b-alert>
                  <b-form-textarea
                    class="border-secondary ml-0"
                    id="inputprompt"
                    v-model="promptOutput"
                    size="sm"
                    placeholder="Paste your script here or Generate it with Ai from a prompt"
                    rows="4"
                    maxlength ="2000"
                    @input="onPromptOutput"
                    :disabled="!aiScriptEnabled || aiScriptSpinner[0]"
                  ></b-form-textarea>
                  <p id="textCounter" class="my-0 text-secondary"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon> Script Characters: {{ promptOutput.length }} / 2000</p>
                  <p id="textCounter" class="my-0 text-secondary"><b-icon icon="stopwatch" variant="secondary" font-scale="1" aria-hidden="true"></b-icon> Estimated Voiceover duration: {{ Math.floor(promptOutput.length/17) }} seconds</p>
                  
                </b-card>
                <voice-over 
                :message="actorMessage"
                v-on:voice-input="updateVoiceMessage"
                v-show="aiScriptEnabled"> 
                </voice-over>

                <b-card v-show="aiScriptEnabled" header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Background-Music (optional) <b-badge v-b-tooltip.bottom title="Upload your music file (Supported formats: mp3 / Max-size: 20MB / Min duration: 90 seconds), the music will be auto added as background music for the genereted video." pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
                  </template>
                  <div id="musiccontainer"></div>

                  <b-alert v-if="musicmessage" variant="danger" show><p>{{ musicmessage }}</p></b-alert>

                  <div class="mt-2 mb-2">
                  <!-- Styled -->

              <!-- ERRORS -->
                    <div class="alert alert-danger" v-show="errors.any('submitmusic')">
                      <div v-if="errors.has('submitmusic.music')">
                        <p>{{ errors.first('submitmusic.music') }}</p>
                      </div>
                    </div>

                    <b-form class="mb-2" data-vv-scope="submitmusic">
                      <b-form-file
                      id="musicfileInput"
                      v-model="music"
                      v-validate="'size:20971|ext:mp3'"
                      data-vv-as="music"
                      :state="Boolean(music)"
                      placeholder = "Upload MP3 File"
                      drop-placeholder="Drop file here..."
                      name = "music"
                      @input="musicInput"
                      
                    ></b-form-file>
                    </b-form>
                    <b-button size="sm" @click.prevent="removeMusic" :disabled="!music">Remove Music</b-button>
                  </div>

                </b-card>
        </b-col>

        </b-row>


        <b-row class="justify-content-center pb-4">
        <b-col xs="12" md="8" class="p-2">
              <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0 pt-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Settings <b-badge v-b-tooltip.bottom title="Select your main video language, Set the output video format, choose colors, position and other text decoration properties" class="mb-0 text-secondary" pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
                  </template>
                  <div class="mt-2 mb-2 text-secondary">
                  <b-form-group label="Main language">
                    <b-form-select
                          class="w-100 mt-0"
                          size="sm"
                          id="alphalang"
                          v-model="selectedLanguage"
                          :options="languageOptions"
                          required
                          @input="onSelectLanguage"
                          :disabled="animationCanvas || (!file && images.length==0)"
                          >
                          <template #first>
                            <b-form-select-option value="english">English</b-form-select-option>
                          </template>
                    </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="mt-2 mb-0 text-secondary">
                      <b-form-group  label="Available Fonts">  
                        <b-form-select
                              class="w-100 mt-0"
                              size="sm"
                              id="alphafont"
                              v-model="selectedFont"
                              :options="fontOptions"
                              required
                              @input="onSelectFont"
                              :disabled="animationCanvas || (!file && images.length==0)"
                              >
                              <template #first>
                                <b-form-select-option value="Arial">Default</b-form-select-option>
                              </template>
                        </b-form-select>
                      </b-form-group>
                  </div>

                  <div class="mt-2 mb-2 text-secondary">
                  <b-form-group label="Video Dimensions" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="Dimensions-group-1"
                      v-model="videoDimensions"
                      :options="videoDimensionsOptions"
                      :aria-describedby="ariaDescribedby"
                      name="Dimensions-options"
                      @input="onWindowResize"
                      :disabled="animationCanvas || (!file && images.length==0)"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <div class="mt-2 mb-2">
                    <div class="text-secondary">
                      <b-form-checkbox v-model="checkedDarkFilter" name="dark-check-button" @input="onWindowResize" :disabled="animationCanvas || (!file && images.length==0)" switch>
                        Dark Filter
                      </b-form-checkbox>
                    </div>
                </div>

                <div class="mt-2 mb-2 text-secondary">
                  <b-form-group label="Text vertical position" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="alignment-group-1"
                      v-model="alignment"
                      :options="alignOptions"
                      :aria-describedby="ariaDescribedby"
                      name="alignment-options"
                      @input="onWindowResize"
                      :disabled="animationCanvas || (!file && images.length==0)"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <div class="mt-2 mb-2 text-secondary">
                  <b-form-group label="Text Size" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="fontSize-group-1"
                      v-model="fontSize"
                      :options="fontSizeOptions"
                      :aria-describedby="ariaDescribedby"
                      name="fontSize-options"
                      @input="onWindowResize"
                      :disabled="animationCanvas || (!file && images.length==0)"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <div class="mt-2 mb-2 text-secondary">
                  <b-form-group label="Text Length"  v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="textLength-group-1"
                      v-model="textLength"
                      :options="textLengthOptions"
                      :aria-describedby="ariaDescribedby"
                      name="textLength-options"
                      @input="onWindowResize"
                      :disabled="animationCanvas || (!file && images.length==0)"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <label class="mb-0 text-secondary">Text Color</label>
                <div class="mt-2 mb-2" style="display: flex; flex-direction: row;">

                    <colorpicker
                    id="fontColor"
                    class="text-secondary"
                    value="#ffffff"
                    label="Text"
                    coloredItem="fontColor"
                    v-on:color-changed="onSelectColer($event)" 
                    :disabledColor="animationCanvas || (!file && images.length==0)"/>
                    <colorpicker
                    id="spelledColor"
                    class="text-secondary"
                      value="#fbeb04"
                      label="Word"
                      coloredItem="spelledColor"
                      v-on:color-changed="onSelectColer($event)" 
                      :disabledColor="animationCanvas || (!file && images.length==0)"/>
                    <colorpicker
                    id="outlineColor"
                    class="text-secondary"
                      value="#000000"
                      label="Outline/Background"
                      coloredItem="outlineColor"
                      v-on:color-changed="onSelectColer($event)" 
                      :disabledColor="animationCanvas || (!file && images.length==0)"/>
                  
                </div>

                <div class="mt-2 mb-2">
                    <div class="text-secondary">
                      <b-form-checkbox v-model="checkedBackgound" name="check-button" @input="onWindowResize" :disabled="animationCanvas || (!file && images.length==0)" switch>
                        Text Background
                      </b-form-checkbox>
                    </div>
                </div>

                <div v-show="selectedAlphabet=='latin'" class="mt-2 mb-2">
                    <div class="text-secondary">
                      <b-form-checkbox v-model="checkedUpperCase" name="check-upper" @input="onWindowResize" :disabled="animationCanvas || (!file && images.length==0) || selectedAlphabet!=='latin'" switch>
                        Text UpperCase
                      </b-form-checkbox>
                    </div>
                </div>

                <b-button 
                        class="w-100 mb-2" 
                        size="sm"
                        squared
                        id="Reset"
                        variant="secondary"
                        @click.prevent="resetSettings"
                        :disabled="animationCanvas || (!file && images.length==0)"
                        >Reset</b-button>  
              </b-card>

              <b-row no-gutters>
            <b-col md="12" class="p-1">
              <b-button 
                  class="w-100 mt-2" 
                  size="md"
                  squared 
                  id="submit-1"
                  variant="primary"
                  @click.prevent="generate"
                  :disabled="animationCanvas || (!file && images.length==0) || aiScriptSpinner[0]"
                  > Generate</b-button>
              </b-col>  
          </b-row>

            </b-col>
            <b-col xs="12" md="4" class="p-2" style="min-width: 300px;">
              <b-card header-tag="header" style="max-width: 300px;" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0 pt-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Output-preview <b-badge v-b-tooltip.bottom title="This is just a low resolution preview of your fomatting and colors. If the language selected is LATIN (like English, Spanish..) the preview will show English text by default, The text is not extracted from input video. The generated video will contain the transcribed words from the input video and with high resolution than this preview." class="mb-0 text-secondary" pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
                  </template>
                  
                  <b-button 
                        class="w-100" 
                        size="sm"
                        squared 
                        color="light"
                        id="playCanvas"
                        variant="secondary"
                        @click.prevent="playCanvas"
                        :disabled="animationCanvas || (!file && images.length==0)"
                        >Animate ({{ selectedAlphabet }}) ►</b-button>
                  <div id="canvaContainer" style="width: 100%">
                    <canvas
                        ref="previewCanvas"
                        style="user-select: none;"
                      />
                    </div>
                  </b-card>
            </b-col>
      </b-row>
     
      <videowidget v-if="showWidgets"></videowidget>
      <b-row>
        <videotable></videotable>
      </b-row>


      <!-- Info modal: audio created succesfully -->
      <b-modal 
        id="videoCreatedModal"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div v-if="videoProcessing[0]" style="text-align: center;">
            <p style="white-space: pre-line;">{{ videoProcessing[1] }}</p>
            <div class="text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
          </div> 
          <div v-else-if="videosaveSuccess" style="text-align: center;">
              <p>Video data uploaded successfully!<br> It's Processing now...<br> It may take few seconds/minutes to be Completed.</p>
              <b-icon icon="check2-circle" font-scale="3" animation="throb" variant="success"></b-icon>
              <b-button class="mt-3" block @click.prevent="$bvModal.hide('videoCreatedModal')">Close</b-button>
          </div>
          <div v-else-if="videosaveFailed[0]" style="text-align: center;">
              <p style="white-space: pre-line;">{{ videosaveFailed[1] }}</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
              <b-button class="mt-3" block @click.prevent="$bvModal.hide('videoCreatedModal')">Close</b-button>
          </div>
          
        </b-modal>

        <b-modal 
        id="videoUploading"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div v-if="videLoading[0]" style="text-align: center;">
            <p style="white-space: pre-line;">{{ videLoading[1] }}</p>
            <div class="text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
          </div> 


          <div v-else style="text-align: center;">
              <p style="white-space: pre-line;">{{ videLoading[1] }}</p>
              <b-icon v-show="videLoading[2] == 'loaded'" icon="check2-circle" font-scale="3" animation="throb" variant="success"></b-icon>
              <b-button v-show="videLoading[2] == 'loaded'" class="mt-3" block @click.prevent="hideVideoUploadingModal">Close</b-button>
              <b-icon v-show="videLoading[2] == 'error'" icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
              <b-button v-show="videLoading[2] == 'error'" class="mt-3" block @click.prevent="hideVideoErrorModal">- Close -</b-button>
          </div>


          
        </b-modal>

        <b-modal 
        id="leaveConfirm"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div style="text-align: center;">
              <p style="white-space: pre-line;">If you leave this page all entered data will be lost!<br> Are you sure?</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="warning"></b-icon>
          </div>
          <b-button class="mt-3" variant="warning" block @click.prevent="confirmedToLeave">Leave</b-button>
          <b-button class="mt-3" variant="primary" block @click.prevent="cancelToleave">Do Not Leave</b-button> 
        </b-modal>

        <b-modal 
        id="alertmodal"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div style="text-align: center;">
              <p style="white-space: pre-line;">{{ alertMessage }}</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="warning"></b-icon>
          </div>
          <b-button class="mt-3" variant="warning" block @click.prevent="$bvModal.hide('alertmodal')">Close</b-button>
        </b-modal>
      
 </b-container>
</template>

<script>
  import { io } from 'socket.io-client';
  import VideoPlayer from "@/components/VideoPlayer.vue";
  import VideoTrimmer from "@/components/VideoTrimmer.vue";
  import VIDEOWidget from "@/components/VideoWidgets.vue";
  import VIDEOTable from "@/components/VideoTable.vue";
  import authHeader from '../services/auth-header';
  import ColorPicker from '@/components/ColorPicker.vue';
  import Voiceover from "@/components/Voiceover.vue";

export default {
  components: {
    'videoplayer': VideoPlayer ,
    'videotrimmer': VideoTrimmer ,
    'videowidget': VIDEOWidget ,
    'videotable': VIDEOTable ,
    'colorpicker': ColorPicker ,
    'voice-over': Voiceover,
  },

  data() {
    return {
      ffmpeg: null,
      trimmedBuffer : null,
      trimmedMp3Buffer : null,
      //baseURL: 'http://localhost:8080', 
      baseURL: 'https://speechorapp.univerhome.com',
      socketio : null,
      showWidgets: true,
      imgVideoDuration: 60, 
      imgVideoDurationOptions: [
        { text: '60 sec', value: 60},
          { text: '90 sec', value: 90},
        ],
      alignment: 5, //buttom:2 or middle:5 or top:8
      alignOptions: [
          { text: 'Bottom', value: 2},
          { text: 'Middle', value: 5 },
          { text: 'Top', value: 8 },
        ],
      fontSize: 16, 
      fontSizeOptions: [
          { text: 'Small', value: 14}, //18px
          { text: 'Medium', value: 16}, //21px
          { text: 'Big', value: 18 }, //24px
        ],
      textLength: 3, 
      textLengthOptions: [
        { text: 'By Sentence', value: 3},
          { text: 'By Word', value: 1}, 
        ],
      videoDimensions: "reelcropped", 
      videoDimensionsOptions: [
          { text: 'Reel Cropped', value: "reelcropped" },
          { text: 'Reel unCropped', value: "reeluncropped" },
          { text: 'Normal', value: "samesize" },
        ],

      selectedAlphabet: "latin",

      loadedFonts:[],
      selectedFont: "Arial",
      fontOptions: [
            {"text": "CabinSketch", "value": "Cabin Sketch Regular"},
            {"text": "FontdinerSwanky", "value": "Fontdiner Swanky Regular"},
            {"text": "FreckleFace", "value": "Freckle Face"},
            {"text": "GloriaHallelujah", "value": "Gloria Hallelujah"},
            {"text": "Lacquer", "value": "Lacquer Regular"},
            {"text": "ProtestRevolution", "value": "Protest Revolution Regular"},
            {"text": "RubikMoonrocks", "value": "Rubik Moonrocks Regular"},
            {"text": "SpicyRice", "value": "Spicy Rice"}
        ],

      selectedLanguage: "english",
      languageOptions: [
        {"text": "Arabic", "value": "arabic"}, //noto ar
        {"text": "Afrikaans", "value": "afrikaans"}, //noto sans
        {"text": "Armenian", "value": "armenian"}, //noto armenian
        {"text": "Azerbaijani", "value": "azerbaijani"}, //noto sans 
        {"text": "Belarusian", "value": "belarusian"}, //noto sans 
        {"text": "Bosnian", "value": "bosnian"}, //noto sans 
        {"text": "Bulgarian", "value": "bulgarian"}, //noto sans
        {"text": "Catalan", "value": "catalan"}, //noto sans
        {"text": "Chinese", "value": "chinese"}, //noto cn
        {"text": "Croatian", "value": "croatian"}, //noto sans
        {"text": "Czech", "value": "czech"}, //noto sans
        {"text": "Danish", "value": "danish"}, //noto sans
        {"text": "Dutch", "value": "dutch"},  //noto sans
        {"text": "Estonian", "value": "estonian"}, //noto sans
        {"text": "Finnish", "value": "finnish"}, //noto sans
        {"text": "French", "value": "french"}, //noto sans
        {"text": "Galician", "value": "galician"}, //noto sans
        {"text": "German", "value": "german"}, //noto sans
        {"text": "Greek", "value": "greek"}, //noto sans
        {"text": "Hebrew", "value": "hebrew"}, //noto hebrew
        {"text": "Hindi", "value": "hindi"}, //noto sans
        {"text": "Hungarian", "value": "hungarian"}, //noto sans
        {"text": "Icelandic", "value": "icelandic"}, //noto sans
        {"text": "Indonesian", "value": "indonesian"}, //noto sans
        {"text": "Italian", "value": "italian"}, //noto sans
        {"text": "Japanese", "value": "japanese"}, //noto cn
        {"text": "Kannada", "value": "kannada"}, //noto kannada 
        {"text": "Kazakh", "value": "kazakh"}, //noto sans
        {"text": "Korean", "value": "korean"}, //noto cn
        {"text": "Latvian", "value": "latvian"}, //noto sans
        {"text": "Lithuanian", "value": "lithuanian"}, //noto sans
        {"text": "Macedonian", "value": "macedonian"}, //noto sans
        {"text": "Malay", "value": "malay"}, //noto sans
        {"text": "Marathi", "value": "marathi"}, //noto sans
        {"text": "Maori", "value": "maori"}, //noto sans
        {"text": "Nepali", "value": "nepali"}, //noto sans
        {"text": "Norwegian", "value": "norwegian"}, //noto sans
        {"text": "Persian", "value": "persian"}, //noto ar
        {"text": "Polish", "value": "polish"}, //noto sans
        {"text": "Portuguese", "value": "portuguese"}, //noto sans
        {"text": "Romanian", "value": "romanian"}, //noto sans
        {"text": "Russian,", "value": "russian"}, //noto sans
        {"text": "Serbian", "value": "serbian"}, //noto sans
        {"text": "Slovak", "value": "slovak"}, //noto sans
        {"text": "Slovenian", "value": "slovenian"}, //noto sans
        {"text": "Spanish", "value": "spanish"}, //noto sans
        {"text": "Swahili", "value": "swahili"}, //noto sans
        {"text": "Swedish", "value": "swedish"}, //noto sans
        {"text": "Tamil", "value": "tamil"}, //noto tamil
        {"text": "Thai", "value": "thai"}, //noto thai
        {"text": "Turkish", "value": "turkish"}, //noto sans
        {"text": "Ukrainian", "value": "ukrainian"}, //noto sans
        {"text": "Urdu", "value": "urdu"}, //noto ar
        {"text": "Vietnamese", "value": "vietnamese"},//noto sans
        {"text": "Welsh", "value": "welsh"} //noto sans

      ],


      previewcanvasWidth : 230,
      previewcanvasHeight : 480,
      previewCanvasContext: null,
      canvaNum:0,
      animationCanvas:false,

      videoCroptype: 0,
      fontColor:"rgba(255,255,255,1)",
      spelledColor:"rgba(255,229,5,1)",
      outlineColor:"rgba(0,0,0,1)",

      primaryColor:"&H00FFFFFF",
      secondaryColor:"&H0002E1FA",
      outlineColorAss:"&H00000000",
      backColor:"&H00000000",
      checkedBackgound:false,
      checkedUpperCase:false,
      checkedDarkFilter:false,

      clicksTimer:[],
      message:'',
      picmessage:'',
      musicmessage:'',
      prompt:'',
      promptOutput:'',
      promptMessage:'',
      promptMessageOutput:'',
      actorMessage:'',
      maxVidTokens:256,
      count: 0,
      file:null,
      picture:null,
      music:null,
      inputFileId:'',
      inputAssId:'',
      inputLogoId:'',
      inputLogoExt:'',
      inputMusicId:'',
      foldersCreated: false,
      startTime: 0,
      endTime: 60,
      endDefaultDuration:60,
      selectedExtImgSize:null,
      startTimeout: null,
      endTimeout: null,
      video : null,
      playBtn:null,
      varSizeOptions : [             
                    {text: "512x512", value:"512_512", disabled: false},
                    {text: "1024x1024", value:"1024_1024", disabled: false},
                  ],

      aiScriptEnabled:false,
      aiScriptGeneration:false,
      inputType: 'video',
      images: [],
      imageslist: [],
      imageSelected: null,
      imagesMessage:'',
      imagesBuffersArray:[],
      alertMessage:''
    };
  },

  watch: {
    leavingPath(newValue){
      if(newValue !== ''){
        this.$bvModal.show('leaveConfirm');
      }
    },
    file(){
      //console.log('file: '+this.file);
      if(this.file == null){
        this.video.src = "";
        this.$store.dispatch("saveFrames", {frames: []});
        document.getElementById("trimmerdiv").style.pointerEvents = "none";
        this.playBtn.setAttribute('disabled', 'disabled');
        this.restartBtn.setAttribute('disabled', 'disabled');
      } else {
        this.playBtn.removeAttribute('disabled');
        this.restartBtn.removeAttribute('disabled');
        document.getElementById("trimmerdiv").style.pointerEvents = "auto";
      }
    },

    videoId(newValue){
      if(newValue !== ''){
        this.checkFilesFfmperServer();
        //console.log("checkFilesFfmperServer called");
      }
      
    },

    inputType(){
       this.onWindowResize();
    },

    socket(newValue){
       //!!!! this watcher is not workin yet!!!
      if(newValue !== ''){
          //console.log("newValue socket url")
          this.socketio = io(newValue);
          this.socketEvents();
      }
    },
    promptOutputResult(newValue){
      this.promptOutput = newValue;
      this.aiScriptGeneration = false;
      this.checkpromptOutput();
    }
  },
 
  computed: {

      socket(){
        return this.$store.state.socket;
      },

      videoId(){
        return this.$store.state.videoId;
      },

      videoPackID(){
        return this.$store.state.videoPackID;
      },

      subStatus(){
        return this.$store.state.videoSubStatus;
      },

      allframes(){
        return this.$store.state.frames;
      },

      duration(){ 
        return this.$store.state.duration;
      },

      videLoading(){
        return this.$store.state.videoLoad;
      },

      videoProcessing(){
        return this.$store.state.videoprocessingSpinner;
      },

      videosaveSuccess(){
        return this.$store.state.videosuccessSpinner;
      },

      videosaveFailed(){
        return this.$store.state.videofailedSpinner;
      },

      promptOutputResult(){
        return this.$store.state.promptVidOutput;
      },

      aiScriptSpinner(){
        return this.$store.state.aiSriptSpinner;
      },

      leavingPath(){
        return this.$store.state.leavingPath;
      },

 
  },


  beforeUnmount () {
    // Remove the window resize and mousemove event listeners
    window.removeEventListener('resize', this.delayOnWindowResize);
    window.removeEventListener('click', this.togglePlay);
    window.removeEventListener('click', this.restartPlay);
    window.removeEventListener('play', this.updateToggleButton);
    window.removeEventListener('pause', this.updateToggleButton);
  },

  mounted(){

    var scriptffmpeg = document.createElement('script');
    /*scriptffmpeg.onload = function () {
          console.log('script scriptffmpeg loaded');
    }*/
    scriptffmpeg.src = '/ffjs/eelodf.js';
    document.head.appendChild(scriptffmpeg);

    this.$store.dispatch("updateVideoSubscriptionStatus");
    if(this.$store.state.isVideoSubscribed == false){
      this.$router.push('/Subscription_Video_Transcriber');
    }

    //console.log("subscription status: ", this.$store.state.videoSubStatus);

    this.$store.dispatch("getSocketServer", {router: this.$router});

    window.addEventListener('resize', this.onWindowResize);
    this.setCanvasWidth();

    // Get the canvas element and its context
    this.previewCanvasContext = this.$refs.previewCanvas.getContext('2d');

    // Set the canvas width and height
    this.$refs.previewCanvas.width = this.$refs.previewCanvas?.offsetWidth;

    if(this.videoDimensions !== 'samesize'){
      this.previewcanvasHeight = 16 * this.previewcanvasWidth / 9;
    }
    
    this.$refs.previewCanvas.height = this.previewcanvasHeight;



    this.video = document.getElementById("videoshow");
    this.video.onplay = () => {this.updateToggleButton()};
    this.video.onpause = () => {this.updateToggleButton()};

    this.playBtn = document.getElementById("toggleButton");
    this.playBtn.onclick = () => {this.togglePlay()};
    
    this.restartBtn = document.getElementById("restartButton");
    this.restartBtn.onclick = () => {this.restartPlay()};

    this.file = null;

    
  },
 
  methods: {

    confirmedToLeave(){
      this.$store.dispatch("updateLeaveConfirmed", { value: true });
      this.$bvModal.hide('leaveConfirm');
      if(this.$route.path !== this.$store.state.leavingPath){
        this.$router.push(this.$store.state.leavingPath);
        this.$store.dispatch("updateLeaveConfirmed", { value: false });
        this.$store.dispatch("updateLeavingPath", { value: '' });
      } else {
        this.$store.dispatch("updateLeaveConfirmed", { value: false });
        this.$store.dispatch("updateLeavingPath", { value: '' });
      }
      
    },

    cancelToleave(){
      this.$store.dispatch("updateLeaveConfirmed", { value: false });
      this.$store.dispatch("updateLeavingPath", { value: '' });
      this.$bvModal.hide('leaveConfirm')
    },

    updateVoiceMessage(){
      this.actorMessage = "";
    },

    onAiScriptCheck(){
      this.promptMessage = "";
      this.promptMessageOutput = "";
      this.actorMessage = "";
    },

    resetSettings(){
      this.alignment = 5; 
      this.fontSize = 16;
      this.textLength = 3;
      this.videoDimensions = "reelcropped";
      this.selectedAlphabet = "latin";

      this.selectedLanguage = "english";
      this.videoCroptype = 0;
      this.fontColor = "rgba(255,255,255,1)";
      this.spelledColor = "rgba(255,229,5,1)";
      this.outlineColor = "rgba(0,0,0,1)";

      this.primaryColor = "&H00FFFFFF";
      this.secondaryColor = "&H0002E1FA";
      this.outlineColorAss = "&H00000000";
      this.backColor = "&H00000000";
      this.checkedBackgound = false;
      this.checkedUpperCase = false;
      this.checkedDarkFilter = false;

      const resetFontColor = document.getElementById("fontColor");
      resetFontColor.value = '#ffffff';

      const resetspelledColor = document.getElementById("spelledColor");
      resetspelledColor.value ='#fbeb04';

      const resetoutlineColor = document.getElementById("outlineColor");
      resetoutlineColor.value ='#000000';

      this.onWindowResize();
    },

    onSelectLanguage(){

      const alphax = this.$store.state.alphabets.filter(element => element.aphabet == this.selectedLanguage);
      const arabicArray = ["arabic", "persian", "urdu"];

      if(alphax.length > 0){
        this.selectedFont = "Arial";
        if(arabicArray.includes(this.selectedLanguage)){
          //list arabic fonts
          this.fontOptions = [
            {"text": "ArefRuqaa", "value": "Aref Ruqaa Regular"},
            {"text": "Amiri", "value": "Amiri Regular"},
            {"text": "Lalezar", "value": "Lalezar-Regular"},
            {"text": "Marhey", "value": "Marhey Regular"},
            {"text": "Rakkas", "value": "Rakkas Regular"},
            {"text": "ReemKufi", "value": "Reem Kufi Regular"} 
          ];
        } else {
          //Default arial or Normal
          this.fontOptions = [];
        }
      } else {
        //list latin fonts

        this.selectedFont = "Arial";
        this.fontOptions = [
            {"text": "CabinSketch", "value": "Cabin Sketch Regular"},
            {"text": "FontdinerSwanky", "value": "Fontdiner Swanky Regular"},
            {"text": "FreckleFace", "value": "Freckle Face"},
            {"text": "GloriaHallelujah", "value": "Gloria Hallelujah"},
            {"text": "Lacquer", "value": "Lacquer Regular"},
            {"text": "ProtestRevolution", "value": "Protest Revolution Regular"},
            {"text": "RubikMoonrocks", "value": "Rubik Moonrocks Regular"},
            {"text": "SpicyRice", "value": "Spicy Rice"}
        ];
      }

      this.onWindowResize();

    },

    onSelectFont(){
      this.onWindowResize();
      //reload canvas if font don't loaded yet
      if(!this.loadedFonts.includes(this.selectedFont) && this.selectedFont!=="Arial"){
        this.loadedFonts.push(this.selectedFont);
        setTimeout(() => this.onWindowResize() , 1500);
      }
    },

    onSelectColer(event){
      const item = event.item;
      const color = event.color;

      const r = parseInt(color.substr(1,2), 16);
      const g = parseInt(color.substr(3,2), 16);
      const b = parseInt(color.substr(5,2), 16);

      const assColor = '&H00'+color.substr(5,2).toUpperCase() + color.substr(3,2).toUpperCase() + color.substr(1,2).toUpperCase();
      //console.log(assColor)
      //console.log(assColor.substr(2,2), assColor.substr(4,6));

      switch (item) {
        case 'fontColor' :
          this.fontColor = `rgba(${r},${g},${b},1)`;
          this.primaryColor = assColor;
          break;

        case 'spelledColor':
          this.spelledColor = `rgba(${r},${g},${b},1)`;
          this.secondaryColor = assColor;
          break;

        case 'outlineColor':
          this.outlineColor = `rgba(${r},${g},${b},1)`;
          this.outlineColorAss = assColor;
          break;
      }
      this.modifyFrame();
    },

    modifyFrame(){
        if(!this.file){
          if(this.imageslist.length==0){
            return;
          } 
        }
          // Clear the canvas
        this.previewCanvasContext.clearRect(0, 0, this.previewcanvasWidth, this.previewcanvasHeight);

        const previewImg = new Promise((resolve, reject) => {
            const img = new Image();
            //img.src = this.testImg;
            if (this.inputType == "video"){
              img.src = this.$store.state.frames[5];
            } else {
              img.src = this.imageslist.children[0].children[0].getAttribute("src");
            }
            
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
          });
        previewImg.then( image => {

          //copWith
          var newImgHeight = image.height/image.width * this.previewcanvasWidth;

          if(this.videoDimensions == 'reelcropped') {
            
            if(image.width >= image.height || (image.width / image.height) > 0.5625){
              this.videoCroptype = 0;
              this.setCanvasWidth()
              this.$refs.previewCanvas.height = this.previewcanvasHeight;
              if(this.checkedDarkFilter==true){
                this.previewCanvasContext.filter = "brightness(25%)";
              }
              this.previewCanvasContext.drawImage(image, 
                                                (image.width/2) - (9 / 16 * image.height)/2, 
                                                0, 
                                                (9 / 16 * image.height),
                                                image.height,
                                                0, 
                                                0, 
                                                this.previewcanvasWidth, 
                                                this.previewcanvasHeight
                                                );
            } else {
              this.videoCroptype = 1;
              this.setCanvasWidth ()
              this.$refs.previewCanvas.height = this.previewcanvasHeight;
              if(this.checkedDarkFilter==true){
                this.previewCanvasContext.filter = "brightness(25%)";
              }
              this.previewCanvasContext.drawImage(image,
                                                0, 
                                                (image.height/2)-((image.width*16/9)/2), 
                                                image.width,
                                                (image.width*16/9),
                                                0, 
                                                0, 
                                                this.previewcanvasWidth, 
                                                this.previewcanvasHeight
                                                );

            }

            if (this.inputType == "images"){this.videoCroptype = 0;}

            this.previewCanvasContext.filter = "none";
              
          }
          else if (this.videoDimensions == 'reeluncropped'){
            this.videoCroptype = 2;
            this.setCanvasWidth ()
            this.$refs.previewCanvas.height = this.previewcanvasHeight;
            
            
            if(image.width >= image.height || (image.width / image.height) > 0.5625){
              this.setCanvasWidth ()
              this.$refs.previewCanvas.height = this.previewcanvasHeight;
              if(this.checkedDarkFilter==true){
                this.previewCanvasContext.filter = "brightness(25%)";
              }
              this.previewCanvasContext.filter = "blur(1.5rem)";
              this.previewCanvasContext.drawImage(image, 
                                                (image.width/2) - (9 / 16 * image.height)/2, 
                                                0, 
                                                (9 / 16 * image.height),
                                                image.height,
                                                0, 
                                                0, 
                                                this.previewcanvasWidth, 
                                                this.previewcanvasHeight
                                                );
            }
            this.previewCanvasContext.filter = "none";
            if(this.checkedDarkFilter==true){
              this.previewCanvasContext.filter = "brightness(25%)";
            }
            if (this.inputType == "video"){
              this.previewCanvasContext.drawImage(image, 
                                                0, 
                                                0, 
                                                image.width,
                                                image.height,
                                                0, 
                                                (this.previewcanvasHeight/2)-(newImgHeight/2), 
                                                this.previewcanvasWidth, 
                                                newImgHeight
                                                );
            } else {
              this.previewCanvasContext.drawImage(image, 
                                                0, (image.height/2) - (9 / 16 * image.width)/2, 
                                                image.width, (9 / 16 * image.width),

                                                0, (this.previewcanvasHeight/2)-(9 / 16 * this.previewcanvasWidth)/2, 
                                                this.previewcanvasWidth, (9 / 16 * this.previewcanvasWidth)
                                                );
            }
            this.previewCanvasContext.filter = "none";
          }
          else if (this.videoDimensions == 'samesize'){
            this.videoCroptype = 3;
            this.setCanvasWidth ()
            if (this.inputType == "video"){
              this.previewcanvasHeight = newImgHeight;
              this.$refs.previewCanvas.height = newImgHeight;
              if(this.checkedDarkFilter==true){
                this.previewCanvasContext.filter = "brightness(25%)";
              }
              this.previewCanvasContext.drawImage(image, 
                                                0, 
                                                0, 
                                                this.previewcanvasWidth,
                                                newImgHeight
                                                );
            } else {
              this.previewcanvasHeight = 9 / 16 * this.previewcanvasWidth;
              this.$refs.previewCanvas.height = 9 / 16 * this.previewcanvasWidth;
              if(this.checkedDarkFilter==true){
                this.previewCanvasContext.filter = "brightness(25%)";
              }
              this.previewCanvasContext.drawImage(image, 
                                                0, 
                                                (image.height/2) - (9 / 16 * image.width)/2, 
                                                image.width,
                                                (9 / 16 * image.width),
                                                0, 
                                                0, 
                                                this.previewcanvasWidth, 
                                                this.previewcanvasHeight
                                                );
            }
            
            this.previewCanvasContext.filter = "none";
          }

          let pxfontSize;
          switch (this.fontSize) {
            case 14:
              pxfontSize = this.checkedUpperCase ? 16: 18; //18px; 14pt
              break;
            case 18:
              pxfontSize = this.checkedUpperCase ? 20: 24; //24px; //18pt
              break;
            default:
              pxfontSize = this.checkedUpperCase ? 18 : 21; //21px; //16pt

          }
          

          let vAlignment;
          switch (this.alignment) {
            case 2: //buttom
              vAlignment = this.previewcanvasHeight*0.75;
              break;
            case 8: //top
              vAlignment = this.previewcanvasHeight*0.25;
              break;
            default: //middle
              vAlignment = this.previewcanvasHeight/2; 

          }

          this.previewCanvasContext.strokeStyle = this.outlineColor;
          this.previewCanvasContext.lineWidth = 3;
          this.previewCanvasContext.lineJoin = "round";
          this.previewCanvasContext.miterLimit = 2;
          this.previewCanvasContext.font = `bold ${pxfontSize.toString()}px "${this.selectedFont}"`;

          var words = this.$store.state.alphabets[0].words;
          this.selectedAlphabet = "latin";

          const alpha = this.$store.state.alphabets.filter(element => element.aphabet == this.selectedLanguage);

          if(alpha.length > 0){
            this.checkedUpperCase = false;
            this.selectedAlphabet = alpha[0].aphabet;
            words = alpha[0].words;
          }

          if(this.checkedUpperCase){
            words = ["HI!", "FROM", "SPEECHOR"];
          }
             
          
          var animatedWord = this.canvaNum;
          if(this.selectedLanguage == "arabic" || this.selectedLanguage == "hebrew"){
              switch(this.canvaNum){
                case 0:
                  animatedWord = 2;
                  break
                case 1:
                  animatedWord = 1;
                  break;
                case 2:
                  animatedWord = 0;
                  break
              }
            }

          if(this.textLength == 1){ //sentence
            
            
            const textW = this.previewCanvasContext.measureText(words[animatedWord]).width;
            if(this.checkedBackgound){
              this.previewCanvasContext.fillStyle = this.outlineColor;
              this.previewCanvasContext.fillRect((this.previewcanvasWidth/2)-(textW/2)-2, vAlignment-(this.fontSize*1.5), textW+4, this.fontSize*2);
            }
            
            
            if(!this.checkedBackgound){
            this.previewCanvasContext.strokeText(words[animatedWord], (this.previewcanvasWidth/2)-(textW/2), vAlignment);}

            this.previewCanvasContext.fillStyle = this.spelledColor;
            this.previewCanvasContext.fillText(words[animatedWord], (this.previewcanvasWidth/2)-(textW/2), vAlignment);
            
            

          } else {
             
            const word1 = this.previewCanvasContext.measureText(words[0]).width;
            const word2 = this.previewCanvasContext.measureText(words[1]).width;
            const word3 = this.previewCanvasContext.measureText(words[2]).width;
            const space = 5;
            const spacing = (this.previewcanvasWidth - (word1+word2+word3+(space*2)))/2;

            switch(animatedWord){
              case 0:
                if(this.checkedBackgound){
                this.previewCanvasContext.fillStyle = this.outlineColor;
                this.previewCanvasContext.fillRect(spacing-2, vAlignment-(this.fontSize*1.5), word1+space+word2+space+word3+4, this.fontSize*2);}

                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[0], spacing, vAlignment);}
                this.previewCanvasContext.fillStyle = this.spelledColor;
                this.previewCanvasContext.fillText(words[0], spacing, vAlignment);
                

                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[1], spacing+word1+space, vAlignment);}
                this.previewCanvasContext.fillStyle = this.fontColor;
                this.previewCanvasContext.fillText(words[1], spacing+word1+space, vAlignment);
                
                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[2], spacing+word1+space+word2+space, vAlignment);}
                this.previewCanvasContext.fillText(words[2], spacing+word1+space+word2+space, vAlignment);
                

                break;
              case 1:
                //...
                if(this.checkedBackgound){
                this.previewCanvasContext.fillStyle = this.outlineColor;
                this.previewCanvasContext.fillRect(spacing-2, vAlignment-(this.fontSize*1.5), word1+space+word2+space+word3+4, this.fontSize*2);}

                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[0], spacing, vAlignment);}
                this.previewCanvasContext.fillStyle = this.fontColor;
                this.previewCanvasContext.fillText(words[0], spacing, vAlignment);
                
                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[1], spacing+word1+space, vAlignment);}
                this.previewCanvasContext.fillStyle = this.spelledColor;
                this.previewCanvasContext.fillText(words[1], spacing+word1+space, vAlignment);
                
                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[2], spacing+word1+space+word2+space, vAlignment);}
                this.previewCanvasContext.fillStyle = this.fontColor;
                this.previewCanvasContext.fillText(words[2], spacing+word1+space+word2+space, vAlignment);
                
                break;
              case 2:
                if(this.checkedBackgound){
                this.previewCanvasContext.fillStyle = this.outlineColor;
                this.previewCanvasContext.fillRect(spacing-2, vAlignment-(this.fontSize*1.5), word1+space+word2+space+word3+4, this.fontSize*2);}

                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[0], spacing, vAlignment);}
                this.previewCanvasContext.fillStyle = this.fontColor;
                this.previewCanvasContext.fillText(words[0], spacing, vAlignment);
                
                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[1], spacing+word1+space, vAlignment);}
                this.previewCanvasContext.fillStyle = this.fontColor;
                this.previewCanvasContext.fillText(words[1], spacing+word1+space, vAlignment);
                
                if(!this.checkedBackgound){
                this.previewCanvasContext.strokeText(words[2], spacing+word1+space+word2+space, vAlignment);}
                this.previewCanvasContext.fillStyle = this.spelledColor;
                this.previewCanvasContext.fillText(words[2], spacing+word1+space+word2+space, vAlignment);
                
                break;
            }
            
          }

          if(this.picture !== null && this.$store.state.videoPackID !== 100){
            
            this.previewCanvasContext.globalAlpha = 0.25;
            const logoImg = new Promise((resolve, reject) => {
              const logo = new Image();
              //logo.src = this.testImg;
              //console.log(this.picture);

              logo.src = URL.createObjectURL(this.picture);
              logo.onload = () => {
                URL.revokeObjectURL(logo.src);
                resolve(logo);
              };
              logo.onerror = (err) => reject(err);
            });
            logoImg.then(logoimg =>{
              if (this.videoDimensions == 'samesize'){
                if(image.width >= image.height || (image.width / image.height) > 0.5625 ){ //|| (logoimg.width / logoimg.height) > 0.5625
                  var newLogoHeightHorizontal = logoimg.height/logoimg.width * (this.previewcanvasWidth/8);
                  this.previewCanvasContext.drawImage(logoimg, 
                                                  0, 
                                                  0, 
                                                  logoimg.width,
                                                  logoimg.height,
                                                  10,
                                                  10, 
                                                  this.previewcanvasWidth/8, 
                                                  newLogoHeightHorizontal
                                                  );
                }else{
                  var newLogoHeightvertcal = logoimg.height/logoimg.width * (this.previewcanvasWidth/4);
                  this.previewCanvasContext.drawImage(logoimg, 
                                                  0, 
                                                  0, 
                                                  logoimg.width,
                                                  logoimg.height,
                                                  (this.previewcanvasWidth/2)-(this.previewcanvasWidth/8), 
                                                  20, 
                                                  this.previewcanvasWidth/4, 
                                                  newLogoHeightvertcal
                                                  );
                }
                
              } else {
                  var newLogoHeight = logoimg.height/logoimg.width * (this.previewcanvasWidth/4);
                  this.previewCanvasContext.drawImage(logoimg, 
                                                  0, 
                                                  0, 
                                                  logoimg.width,
                                                  logoimg.height,
                                                  (this.previewcanvasWidth/2)-(this.previewcanvasWidth/8), 
                                                  20, 
                                                  this.previewcanvasWidth/4, 
                                                  newLogoHeight
                                                  );
                }

            }).catch(() => {console.log('...')});
           
          }

        }).catch(() => {console.log('...')});
        
    },

    async playCanvas(){
      this.animationCanvas = true;
      if (this.canvaNum <= 2){
        setTimeout(() => {
          //logic..
          this.onWindowResize();

          this.canvaNum+=1
          this.playCanvas();
        }, 700);
      } else {
        this.animationCanvas = false;
        this.canvaNum = 0;
        return;
      }  
    },

    async onWindowResize () {

      this.setCanvasWidth();
      
      // Get the canvas element and its context
      this.previewCanvasContext = this.$refs.previewCanvas ? this.$refs.previewCanvas.getContext('2d') : null;

      if (!this.previewCanvasContext) {
        return;
      }

      // Set the canvas width and height
      this.$refs.previewCanvas.width = this.previewcanvasWidth;
      this.$refs.previewCanvas.height = this.previewcanvasHeight;

      // Set the canvas style width and height
      this.$refs.previewCanvas.style.width = 3 * this.previewcanvasWidth;
      this.$refs.previewCanvas.style.height = 3 * this.previewcanvasHeight;

      await this.$nextTick();

      // Draw the frame
      this.modifyFrame();
    },

    setCanvasWidth () {
      this.previewcanvasWidth = this.$refs.previewCanvas?.offsetWidth;
      if(this.videoDimensions !== 'samesize'){
        this.previewcanvasHeight = 16 * this.previewcanvasWidth / 9;
       
      } 
    },

    hideVideoUploadingModal(){
      this.message='';
      this.$bvModal.hide('videoUploading');
      this.onWindowResize();
    },

    hideVideoErrorModal(){
      this.removeFile();
      this.message='';
      this.$bvModal.hide('videoUploading');
      this.onWindowResize();
    },

    async getVideoDuration(){
      return new Promise((resolve, reject)=>{
        var audioContext = new(window.AudioContext || window.webkitAudioContext)();
        var reader = new FileReader();
        var duration;
        
        reader.onload = function () {
          var videoFileAsBuffer = reader.result; // arraybuffer
          audioContext.decodeAudioData(videoFileAsBuffer).then(function (decodedAudioData) {
            duration = decodedAudioData.duration;
            resolve(duration);
            cleaup();
          });   
        };
        reader.onerror = () => {
            //console.log(error); 
            cleaup();
            reject(null); 
        };
        reader.readAsArrayBuffer(this.file);
        const cleaup = () => {
          reader.onload = null;
          reader.onerror = null;
        }
      });
    },

    //STEP0
    videoInput(){
      if(this.file){

        this.$store.dispatch("framesLoading", {status: "inprogress", message: "Uploading the Video\nIt may take a few seconds Please wait..."});

        this.$bvModal.show('videoUploading');

        var sizePackLimit = 209_715_200;
        var sizePackLimitMB = 200;

        const videoduration = this.getVideoDuration();

        videoduration.then(duration => {
          //console.log("getVideoDuration: ", duration);
          if(duration == null || duration == 'undefined'){
            this.$store.dispatch("framesLoading", {status: "error", message: "Error: We can not read the input data correctly! \nJust close this window"});
            return;
          }

          if(duration < 60){
            this.$store.dispatch("framesLoading", {status: "error", message: "Error: The video duration is less than 60 seconds! \n Upload a longer video \n Just close this window"});
            return;
          }

          if( this.file.size <= sizePackLimit && duration <= 1800 && this.file.type == 'video/mp4'){

            this.$store.dispatch("videoDuration", {duration: duration});

            this.endTime = 60;
            
            this.$validator.validateAll('submitvideo').then(
              async (isValid) => {
                if (isValid) {

                    const URL = window.URL || window.webkitURL;
                    this.video.src = URL.createObjectURL(this.file);
                    this.video.load();
                    this.startTime = 0;

                  
                } else {
                  this.message = 'Please fix these errors!';
                  this.$store.dispatch("framesLoading", {status: "error", message: "Please fix the errors.!\nJust close this window"});
                 
                }
            }, 
            (error) => {
                    this.message = error;
                    this.$store.dispatch("framesLoading", {status: "error", message: error+"\nJust close this window"});
            });
          } else {
            this.message = "Video size must be up to " + sizePackLimitMB + "MB, and Video must be mp4 format";

            this.$store.dispatch("framesLoading", {status: "error", message: "Uploading failed! check the following reasons: \n 1) Video size must be up to " + sizePackLimitMB + "MB;.\n 2) Video must be MP4 format.\n 3) Video duration up to 30 minutes.\n\nJust close this window"});  
          }
        }).catch(() => {
          this.$store.dispatch("framesLoading", {status: "error", message: "Error: We can not read the input data correctly! \nJust close this window."});
            return;
        });

      } 


    },

    removeFile(){
      this.file = null;
      this.trimmedBuffer = null;
      this.message='';
      this.previewCanvasContext.clearRect(0, 0, this.previewcanvasWidth, this.previewcanvasHeight);
      this.$store.dispatch("clearFrames");
    },

    

    //Inside STEP1
    //Local FFMPEG methods
    async videoTrimming(start, end){
        if(this.file === null){ 
          return null;
        }

        if(this.ffmpeg === null){
          //eslint-disable-next-line
          this.ffmpeg = new FFmpegWASM.FFmpeg();

          this.ffmpeg.on('progress', ({ progress }) => {
              //console.log("load", progress);
              this.$store.dispatch("updateTranscriptionModalMessage", {message: `Video trimming in progress ... \n${Math.ceil(progress * 100)} % \nPlease wait!`});
          });

          //remove logs in production
          /*this.ffmpeg.on('log', ({ message }) => {
              console.log(message);
          });*/

          //console.log('Transcoding with (single-threaded)');
          await this.ffmpeg.load({
              wasmURL: `${this.baseURL}/ffjs/eelodf-core.wasm`,
              workerLoadURL: `${this.baseURL}/ffjs/814.eelodf.js`,
              coreURL: `${this.baseURL}/ffjs/eelodf-core.js`,     
          }); 
          //console.log('ffmpeg.load success');
        }

        const fetchFile = async (file) => {
          return new Promise((resolve, reject)=>{
                  const fr = new FileReader();
                  fr.onload = () => {
                      var result = fr.result;
                      if(result instanceof ArrayBuffer){
                        //console.log("result is ArrayBuffer");
                        resolve(new Uint8Array(result)); 
                      } else {
                        reject("Error: not ArrayBuffer");
                      }        
                  }
                  fr.readAsArrayBuffer(file);

            }).then(input => {
              return input;
            }).catch(() => { return null;})  
        };

        const name = this.file.name;
  
        var unintarr = await fetchFile(this.file);
      

        if(unintarr !== null){
          await this.ffmpeg.writeFile(name, unintarr);
          await this.ffmpeg.exec(['-ss', start.toString(), '-to', end.toString(), '-i', name, '-c:v', 'copy', 'output.mp4']);
          var data = await this.ffmpeg.readFile('output.mp4');
          return data.buffer;
        }else{
          //console.log("Error: input data or video not correct");
          return null;
        }
      
    },

    async musicTrimming(start, end){
        if(this.music === null){ 
          return null;
        }

        if(this.ffmpeg === null){
          //eslint-disable-next-line
          this.ffmpeg = new FFmpegWASM.FFmpeg();

          this.ffmpeg.on('progress', ({ progress }) => {
              //console.log("load", progress);
              this.$store.dispatch("updateTranscriptionModalMessage", {message: `Music trimming in progress ... \n${Math.ceil(progress * 100)} % \nPlease wait!`});
          });

          //remove logs in production
          /*this.ffmpeg.on('log', ({ message }) => {
              console.log(message);
          });*/

          //console.log('Transcoding with (single-threaded)');
          await this.ffmpeg.load({
              wasmURL: `${this.baseURL}/ffjs/eelodf-core.wasm`,
              workerLoadURL: `${this.baseURL}/ffjs/814.eelodf.js`,
              coreURL: `${this.baseURL}/ffjs/eelodf-core.js`,     
          }); 
          //console.log('ffmpeg.load success');
        }

        const fetchFile = async (file) => {
          return new Promise((resolve, reject)=>{
                  const fr = new FileReader();
                  fr.onload = () => {
                      var result = fr.result;
                      if(result instanceof ArrayBuffer){
                        //console.log("result is ArrayBuffer");
                        resolve(new Uint8Array(result)); 
                      } else {
                        reject("Error: not ArrayBuffer");
                      }        
                  }
                  fr.readAsArrayBuffer(file);

            }).then(input => {
              return input;
            }).catch(() => { return null;})  
        };

        const name = this.music.name;
  
        var unintarr = await fetchFile(this.music);
      

        if(unintarr !== null){
          await this.ffmpeg.writeFile(name, unintarr);
          await this.ffmpeg.exec(['-ss', start.toString(), '-to', end.toString(), '-i', name, '-c:a', 'copy', 'output.mp3']);
          var data = await this.ffmpeg.readFile('output.mp3');
          return data.buffer;
        }else{
          //console.log("Error: input data or video not correct");
          return null;
        }
      
    },

    reconnectSocket(){
      if(this.$store.state.socket == ''){
          this.$store.dispatch("getSocketServer", {router: this.$router});
        } else {
          this.socketio = io(this.$store.state.socket);
          this.socketEvents();
        }
    },

    socketEvents(){
      /*this.socketio.on("connect", () => {
       console.log(`socket connected`, this.socketio.connected);
      });*/

      this.socketio.on("filesChecked", (data) => {
          this.foldersCreated = data.foldersCreated;
          //Execute the the ffmpeg video generation function from here
          this.sendToffmegServer();
      });


      this.socketio.on("newstartResponse", (msg) => {
          if(msg.status == 1200){
            this.$store.dispatch("updateVideoPreRecords", {router: this.$router, msg: msg.status+':'+msg.message, status: "Success"});
          } else {
            this.$store.dispatch("updateVideoPreRecords", {router: this.$router, msg: msg.status+':'+msg.message, status: "Fail"});
          }
      });

      this.socketio.on("disconnect", () => {
          this.$store.dispatch("cancelVideoProcess", {msg: 'Error 596: Server connection issue, close and try again! \nContact us for help'});
      });
    },

    //STEP1
    generate(){
      if(!this.socketio){
        this.reconnectSocket();
        this.alertMessage="Reconnected to Server Successfully! \nClose this alert now and try again!";
        this.$bvModal.show('alertmodal');
        return;
      }
      
      this.trimmedBuffer = null;
      this.trimmedMp3Buffer = null;
      this.inputFileId = Date.now().toString(36)+Math.random().toString(36).substr(2);
      this.inputAssId = Date.now().toString(36)+Math.random().toString(36).substr(2);

      if(this.inputType == "video"){
        this.videoTranscribe();
      }
      else if(this.inputType == "images"){
        this.imagesTranscribe();
      }
    },

    videoTranscribe(){
      if (this.file !== null && this.socketio.connected){
        //check how fast the click to generate buttons are? if < 5seconds return
        if (this.isTooFastAction()){
          this.alertMessage="Too many actions, try again later! \nFix all showing errors before clicking Generate!";
          this.$bvModal.show('alertmodal');
          return;
        }

        if(this.aiScriptEnabled){
          //check if script exist
          if(this.promptOutput.length < 300){
            this.promptMessageOutput = "Enter or generate a Min of 300 characters of script. To use the original video voice-over disable the Ai Voice-over Section.";
            this.alertMessage="The configuration is not correct! Please Fix Errors!";
            this.$bvModal.show('alertmodal');
            return;
          }

          this.checkpromptOutput();
          if(this.promptMessageOutput){
            this.alertMessage="The configuration is not correct! Please Fix Errors!";
            this.$bvModal.show('alertmodal');
            return;
          }

          //check if voice selected
          if(this.$store.state.selectedActorVid == ''){
            this.actorMessage = "No Voice selected, Please select a voice! Or disable Ai Voice-over section if you don't need it";
            this.alertMessage="The configuration is not correct! Please Fix Errors!";
            this.$bvModal.show('alertmodal');
            return;
          }

          //check music errors
          if(this.music !== null && this.musicmessage !== ""){
            this.musicmessage = "Fix errors! Remove the music file or upload a correct one!";
            this.alertMessage="The configuration is not correct! Please Fix Errors!";
            this.$bvModal.show('alertmodal');
            return;
          }
        }

        //check image errors
        if(this.picture !== null){
          const allowedArray = ['image/png','image/jpg','image/jpeg','image/gif'];
          if(this.picture.size > 1_048_576 || !allowedArray.includes(this.picture.type)){
            this.picmessage = "Fix errors! Remove the image or upload a correct one!";
            this.alertMessage="The configuration is not correct! Please Fix Errors!";
            this.$bvModal.show('alertmodal');
            return;
          }
        }

        

        this.$bvModal.show('videoCreatedModal');
        this.$store.dispatch("updateTranscriptionModalMessage", {message: 'Loading software ressources ... \nIt will take few seconds! \nPlease wait!'});

        const getTrimmedBuffer = this.videoTrimming(this.startTime, this.endTime); //arrayBuffer
        

        getTrimmedBuffer.then(trimmedinput => {
          if (trimmedinput == null) {
            this.$store.dispatch("cancelVideoProcess", {msg: 'Error 1465: reading input video'});
            return;
          };

          //check the size of the trimmed arraybuffer if it's bigger than 100MB 
          if(trimmedinput.byteLength > 90_000_000){
            this.$store.dispatch("cancelVideoProcess", {msg: 'Error 1472: The size of the trimmed video is too big, try to upload a lower resolution version of the input video'});
            getTrimmedBuffer == null;
            return;
          }
          this.trimmedBuffer = trimmedinput;

          if(this.aiScriptEnabled && this.music !== null){
            const getTrimmedMp3Buffer = this.musicTrimming(this.startTime, this.endTime);
            getTrimmedMp3Buffer.then(trimmedmusic => {
              if (trimmedmusic == null) {
                this.$store.dispatch("cancelVideoProcess", {msg: 'Triming music input failed! Try again or remove/change the mp3 file!'});
                return;
              };
              this.trimmedMp3Buffer = trimmedmusic;
              this.$store.dispatch("updateTranscriptionModalMessage", {message: 'Uploading data in progress ... \n Please wait!'});
              this.$store.dispatch("videoTranscribe", {router: this.$router, start: this.startTime, end: this.endTime});
            });
          } else {
            this.trimmedMp3Buffer = null;
            this.$store.dispatch("updateTranscriptionModalMessage", {message: 'Uploading data in progress ... \n Please wait!'});
            this.$store.dispatch("videoTranscribe", {router: this.$router, start: this.startTime, end: this.endTime});
          }

        });
      
      } else {
        this.message = "Errors: Server connection lost or no video uploaded";
        this.alertMessage="The configuration is not correct! Please Fix Errors!";
        this.$bvModal.show('alertmodal');
        //console.log("error: server connection lost or no video uploaded")
      }
    },

    imagesTranscribe(){
      if (this.images.length > 2 && this.images.length < 6 && this.socketio.connected){
        if (this.isTooFastAction()){
          this.alertMessage="Too many actions, try again later! \nFix all showing errors before clicking Generate!";
          this.$bvModal.show('alertmodal');
          return;
        }

        if(!this.aiScriptEnabled){
          this.alertMessage="Please Enable Ai Voice-over Section! It's required for video generation from images";
          this.$bvModal.show('alertmodal');
          return;
        }

        //check if script exist
        if(this.promptOutput.length < 300){
          this.promptMessageOutput = "Enter or generate a Min of 300 characters of script. Enabling the Ai Voice-over Section is requied for images input type.";
          this.alertMessage="The configuration is not correct! Please Fix Errors!";
          this.$bvModal.show('alertmodal');
          return;
        }
        
        this.checkpromptOutput();
        if(this.promptMessageOutput){
          this.alertMessage="The configuration is not correct! Please Fix Errors!";
          this.$bvModal.show('alertmodal');
          return;
        }

        //check if voice selected
        if(this.$store.state.selectedActorVid == ''){
          this.actorMessage = "No Voice selected, Please select a language and a voice!";
          this.alertMessage="The configuration is not correct! Please Fix Errors!";
          this.$bvModal.show('alertmodal');
          return;
        }

        //check music errors
        if(this.music !== null && this.musicmessage !== ""){
          this.musicmessage = "Fix errors! Remove the music file or upload a correct one!";
          this.alertMessage="The configuration is not correct! Please Fix Errors!";
          this.$bvModal.show('alertmodal');
          return;
        }

        //check logo errors
        if(this.picture !== null){
          const allowedArray = ['image/png','image/jpg','image/jpeg','image/gif'];
          if(this.picture.size > 1_048_576 || !allowedArray.includes(this.picture.type)){
            this.picmessage = "Fix errors! Remove the image or upload a correct one!";
            this.alertMessage="The configuration is not correct! Please Fix Errors!";
            this.$bvModal.show('alertmodal');
            return;
          }
        }

        this.$bvModal.show('videoCreatedModal');
        this.$store.dispatch("updateTranscriptionModalMessage", {message: 'Loading software ressources ... \nIt will take few seconds! \nPlease wait!'});
        //add music trimming here
        if(this.music !== null){
          const getTrimmedMp3Buffer = this.musicTrimming(0, this.imgVideoDuration);
          getTrimmedMp3Buffer.then(trimmedmusic => {
            if (trimmedmusic == null) {
              this.$store.dispatch("cancelVideoProcess", {msg: 'Triming music input failed! Try again or remove/change the mp3 file!'});
              return;
            };
            this.trimmedMp3Buffer = trimmedmusic;
            this.$store.dispatch("updateTranscriptionModalMessage", {message: 'Uploading data in progress ... \n Please wait!'});
            this.$store.dispatch("videoTranscribe", {router: this.$router, start: 0, end: this.imgVideoDuration}); 
          });
        } else {
          this.trimmedMp3Buffer = null;
          this.$store.dispatch("updateTranscriptionModalMessage", {message: 'Uploading data in progress ... \n Please wait!'});
          this.$store.dispatch("videoTranscribe", {router: this.$router, start: 0, end: this.imgVideoDuration}); 
        }

      } else {
        if (this.imagesMessage == ""){
          this.imagesMessage = "Errors: Server connection lost or wrong images uploaded";
        } else {
          this.imagesMessage = "Please fix these errors: "+this.imagesMessage;
        }
        
        this.alertMessage="The configuration is not correct! Please Fix Errors!";
        this.$bvModal.show('alertmodal');
      }
    },

    //STEP2
    checkFilesFfmperServer(){
      if (this.socketio.connected && this.$store.state.videoId!==''){
        this.socketio.emit("checkFiles", {
                userId: this.$store.state.currentUId,
                videoId: this.$store.state.videoId,
            });
      } else {
        this.$store.dispatch("updateVideoPreRecords", {router: this.$router, msg: 'Server connection lost or missed parameters', status: "Fail"});
      }
    },

    //STEP3
    sendToffmegServer(){
      //console.log("checkFile finished, is socket still connected, trim buff not null: ", this.socketio.connected, this.trimmedBuffer!==null);
      const background = this.checkedBackgound == false ? 0 : 1;
      const upperCase = this.checkedUpperCase == false ? 0 : 1;
      const darknessFilter = this.checkedDarkFilter == false ? 0 : 1;
      var fontSize = 22;
      switch(this.fontSize){
        case 14:
          fontSize = 18;
          break;
        case 16:
          fontSize = 22;
          break;
        case 18:
          fontSize = 26;
          break;
      }
      if(this.inputType == "video"){
        if (this.file !== null
            && this.trimmedBuffer !== null
            && this.foldersCreated == true
            && this.socketio.connected
            && this.inputFileId!=='' 
            && this.inputAssId!=='' 
            && this.$store.state.videoId!==''
            && this.$store.state.currentUId!==''){

          //console.log("sendToffmegServer ...");
          const p = new Promise((resolve) => {
            if(this.picture !== null
                && this.inputLogoId !== ''
                && this.inputLogoExt !== '')
              {
                  //console.log("Image exist! sending logo image...");

                  const imgfr = new FileReader();

                  imgfr.onloadend = (event) => {
                     var imgResult = event.target.result;
                     cleaup();
                     resolve(imgResult);
                  }

                  imgfr.onerror = () => {
                    //console.log(error);
                    cleaup();
                    resolve(null);
                  }

                  const cleaup = () => {
                    imgfr.onloadend = null;
                    imgfr.onerror = null;
                  };

                  imgfr.readAsArrayBuffer(this.picture);

            } else {
              resolve(null);
            }
          })

          p.then(image => {
            var script = '';
            var voice = '';
            var vlanguage = '';
            var backMusicBuffer = null;
            var backMusicId = '';
            if(this.aiScriptEnabled){
              script = this.promptOutput;
              voice = this.$store.state.selectedActorVid;
              vlanguage = this.$store.state.selectedLangVid;
              if(this.music!==null && this.trimmedMp3Buffer!==null && this.inputMusicId!==''){
                backMusicBuffer = this.trimmedMp3Buffer;
                backMusicId = this.inputMusicId;
              }
            }
            this.socketio.emit("startvid", {
              token: authHeader(),
              subStatus: this.$store.state.videoSubStatus, //if trialing => my logo gif will be added to video
              userId: this.$store.state.currentUId,
              videoId: this.$store.state.videoId,
              inputId: this.inputFileId,
              assId: this.inputAssId,
              vidDuration: this.endTime-this.startTime,

              video: this.trimmedBuffer,
              logo: image,
              music: backMusicBuffer,

              script: script.replace(/['"]/g, "\\$&"),
              voice: voice,
              vlanguage: vlanguage,

              inputLogoId: this.inputLogoId,
              inputMusicId: backMusicId,
              inputLogoExt: this.inputLogoExt,

              selectedFont: this.selectedFont,
              language: this.selectedLanguage,
              videoType: this.videoCroptype,
              darkFilter: darknessFilter,
              alignment: this.alignment,
              byWord: this.textLength,
              fontSize: fontSize,
              primaryColor: this.primaryColor,
              secondaryColor: this.secondaryColor,
              outlineColorAss: this.outlineColorAss,
              isBackColorChecked: background,
              isUpperCaseChecked: upperCase,
              backColor: this.backColor,
            });
          })    
          
        } else {
          //call proccess spinner from here to finsh the process with error: server connection lost or no video uploaded
          this.$store.dispatch("updateVideoPreRecords", {router: this.$router, msg: 'Server connection lost or other parameters missed', status: "Fail"});
        }

      }

      else if(this.inputType == "images"){
        if (this.imagesBuffersArray !== []
            && this.foldersCreated == true 
            && this.socketio.connected
            && this.inputAssId!=='' 
            && this.$store.state.videoId!==''
            && this.$store.state.currentUId!==''){

          //console.log("sendToffmegServer ...");
          const p = new Promise((resolve) => {
            if(this.picture !== null 
                && this.inputLogoId !== ''
                && this.inputLogoExt !== '')
              {
                  //console.log("Image exist! sending logo image...");

                  const imgfr = new FileReader();

                  imgfr.onloadend = (event) => {
                     var imgResult = event.target.result;
                     cleaup();
                     resolve(imgResult);
                  }

                  imgfr.onerror = () => {
                    //console.log(error);
                    cleaup();
                    resolve(null);
                  }

                  const cleaup = () => {
                    imgfr.onloadend = null;
                    imgfr.onerror = null;
                  };

                  imgfr.readAsArrayBuffer(this.picture);

            } else {
              resolve(null);
            }
          })

          p.then(image => {
            var backMusicBuffer = null;
            var backMusicId = '';
            if(this.music!==null && this.trimmedMp3Buffer!==null && this.inputMusicId!==''){
              backMusicBuffer = this.trimmedMp3Buffer;
              backMusicId = this.inputMusicId;
            }
            this.socketio.emit("startimg", {
              token: authHeader(),
              subStatus: this.$store.state.videoSubStatus, //if trialing => my logo gif will be added to video
              userId: this.$store.state.currentUId,
              videoId: this.$store.state.videoId,
              assId: this.inputAssId,
              vidDuration: this.imgVideoDuration,

              images: this.imagesBuffersArray,
              logo: image,
              music: backMusicBuffer,

              script: this.promptOutput.replace(/['"]/g, "\\$&"),
              voice: this.$store.state.selectedActorVid,
              vlanguage: this.$store.state.selectedLangVid,

              inputLogoId: this.inputLogoId,
              inputMusicId: backMusicId,
              inputLogoExt: this.inputLogoExt,

              selectedFont: this.selectedFont,
              language: this.selectedLanguage,
              videoType: this.videoCroptype,
              darkFilter: darknessFilter,
              alignment: this.alignment,
              byWord: this.textLength,
              fontSize: fontSize,
              primaryColor:this.primaryColor,
              secondaryColor:this.secondaryColor,
              outlineColorAss:this.outlineColorAss,
              isBackColorChecked: background,
              isUpperCaseChecked: upperCase,
              backColor:this.backColor,
            });
          })    
          
        } else {
          //call proccess spinner from here to finsh the process with error: server connection lost or no video uploaded
          this.$store.dispatch("updateVideoPreRecords", {router: this.$router, msg: 'Server connection lost or other parameters missed', status: "Fail"});
        }
      }
    },

    isTooFastAction(){
      var currentClickTime;
      if(!Date.now) {
        Date.now = function(){ return new Date().getTime();};
        currentClickTime = Date.now();
      }else{
        currentClickTime = Date.now();
      }
      if(this.clicksTimer.length == 0){
        this.clicksTimer.push(currentClickTime)
        return false;
      }
      else {
        if (currentClickTime-this.clicksTimer[this.clicksTimer.length-1]<5000){
          this.clicksTimer.push(currentClickTime);
          this.clicksTimer.splice(this.clicksTimer.length-2,1);
          return true;
        }
        this.clicksTimer.push(currentClickTime);
        this.clicksTimer.splice(this.clicksTimer.length-2,1);
        return false;
      }  
    },

    //Logo Input
    pictureInput(){
      this.picmessage='';
      if(this.picture !== null){
        
        const allowedArray = ['image/png','image/jpg','image/jpeg','image/gif'];
        if( this.picture.size <= 1_048_576 && allowedArray.includes(this.picture.type) ){
          this.$validator.validateAll('submitpicture').then(
            async (isValid) => {
              if (isValid) {
                this.inputLogoId = Date.now().toString(36)+Math.random().toString(36).substr(2);
                this.inputLogoExt = this.picture.name.slice(this.picture.name.lastIndexOf('.')+1);
               // console.log(this.inputLogoId+'.'+this.inputLogoExt );
                if(this.inputType == "video"){
                  if(this.file !== null && this.$store.state.frames.length > 0){
                    this.onWindowResize();
                  } 
                } else {
                  if(this.images.length > 0){
                    this.onWindowResize();
                  } 
                }
                  
              } else {
                this.picmessage = "Image size must be up to 1MB, Image format must be: png,jpg,jpeg or gif";
               
              }  
          }, 
          (error) => {
                  this.picmessage = error;
                  
          });
        } else {
          this.picmessage = "Image size must be up to 1MB, Image format must be: png,jpg,jpeg or gif";
          
        }
      }

      
    },

    removePicture(){
      this.picture = null;
      this.inputLogoId = '';
      this.inputLogoExt = '';
      this.picmessage='';
      if(this.file !== null && this.$store.state.frames.length > 0){
        this.onWindowResize();
      } 
    },
    getMusicDuration(file){
      return new Promise((resolve)=>{
        const mcontainer = document.getElementById("musiccontainer");
        mcontainer.innerHTML="";
        const audio = document.createElement("audio");
        audio.setAttribute("controls", "");
        audio.setAttribute("src", URL.createObjectURL(file));
        audio.style.width = "100%";
        mcontainer.appendChild(audio);
        audio.addEventListener("loadedmetadata", (event)=>{
          const duration = event.target.duration;
          //console.log(duration)
          resolve(duration)
        });
        audio.addEventListener("error", ()=>{ resolve(null); });
      })
    },
    //Music Input
    musicInput(){
      this.musicmessage='';
      if(this.music !== null){
        if( this.music.size > 20_971_520 || this.music.type!=='audio/mpeg' ){
          this.musicmessage = "Music file size must be up to 20MB, format must be mp3."; 
          return;
        }
        const musicduration = this.getMusicDuration(this.music);
        //musicduration.then(duration => {console.log(duration)})
        
        musicduration.then(duration => {
          //console.log("getVideoDuration: ", duration);
          if(duration == null || duration == 'undefined'){
            this.musicmessage = "Error: We can not read the music file data correctly!"; 
            return;
          }

          if(duration < 90){
            this.musicmessage = "Error: The music duration is less than 90 seconds! Upload a longer track"; 
            return;
          }
          
          this.$validator.validateAll('submitmusic').then(
            async (isValid) => {
              if (isValid) {
                this.inputMusicId = Date.now().toString(36)+Math.random().toString(36).substr(2);
                //console.log("music file id: ", this.inputMusicId )
              
              } else {
                this.musicmessage = "Music file size must be up to 20MB, format must be mp3"; 
              }
          }, 
          (error) => {
                this.musicmessage = error;    
          });
          
        })
      } 
    },

    removeMusic(){
      this.music = null;
      this.trimmedMp3Buffer = null;
      this.inputMusicId = '';
      this.musicmessage='';
      const mcontainer = document.getElementById("musiccontainer");
      mcontainer.innerHTML="";
    },

    async fetchUrl(objURL){
      const blob = await fetch(objURL).then(r => r.blob());
      return blob;
    },

    //integrate this func with ffmpeg func
    async getImagesBufferArray(){
      
        this.imagesBuffersArray = [];

        if(this.images.length > 0){
          for (let i=0; i<this.imageslist.children.length; i++){
            let obj = {};
            let objURL = this.imageslist.children[i].children[0].getAttribute("src");
            let ext = this.imageslist.children[i].getAttribute("id").split(".").pop()
            const fr = new FileReader();
            fr.onload = () => {
                var result = fr.result;
                if(result instanceof ArrayBuffer){
                  //console.log("ArrayBuffer");
                  //console.log(result);
                  obj.file = result;
                  obj.name = "image-" + i + "." + ext;
                  this.imagesBuffersArray.push(obj); 
                }        
            }
            await this.fetchUrl(objURL).then((blob) => fr.readAsArrayBuffer(blob)); 
          }
        }

        return this.imagesBuffersArray;
    
    },

    getImageDimensions(image) {
      return new Promise(resolve=>{

        const imagcheck = async (imge) => {
          let img = new Image();
          img.src = URL.createObjectURL(imge);
          await img.decode();
          let width = img.width;
          let height = img.height;
          URL.revokeObjectURL(img.src);
          return({width, height});
        }
        
        resolve(imagcheck(image));
      })
      
    },

    imagesInput(){
      //console.log('inputimg: ' ,this.images);
      this.imagesMessage = "";
      this.imageSelected = null;
      this.imageslist = document.getElementById('imagescontainer')
      this.imageslist.innerHTML=""

      if(this.images.length > 0){
        if(this.images.length < 3){
          this.imagesMessage += "Select at least 3 images and not more than 5. ";
        }

        if(this.images.length > 5){
          this.images.splice(5);
        }
      }

      //3_145_728
      if(this.images.length > 0){
        for (let i=0; i < this.images.length; i++) {
          if(this.images[i].size > 3_145_728){
            this.imagesMessage += "Some images exceed the Max size per image(3Mb). ";
          }
        }
      }
     
      if(this.images.length > 0){
        let notCorrect=0;
        for (let i=0; i < this.images.length; i++) {
          this.getImageDimensions(this.images[i]).then(({width, height }) => {
            if(width < 500 || height < 500){
              notCorrect++
              if(notCorrect == 1){
                this.imagesMessage += "Some small images found! the Min accepted width=500px and Min accepted height=500px. ";
              }
            }
          })
        }
        
      }         
      if(this.images.length > 0){
        for (let i=0; i < this.images.length; i++) {
          let imgDiv = document.createElement('div');
          imgDiv.setAttribute("id", this.images[i].name)
          imgDiv.style.display = "block";
          imgDiv.style.width = "100%";
          imgDiv.style.backgroundColor = "#000000";
          imgDiv.style.borderRadius = "5px";
          imgDiv.style.padding = "1px";
          imgDiv.style.marginBottom = "3px";

          let nameP = document.createElement('p');
          //nameP.innerHTML = this.images[i].name
          nameP.style.display = "inline-block";
          nameP.style.verticalAlign = "middle";

          let img = document.createElement('img');
          img.style.borderRadius = "5px";
          img.style.display = "inline-block";
          img.style.height="50px";

          img.src = URL.createObjectURL(this.images[i]);

          imgDiv.appendChild(img);
          imgDiv.appendChild(nameP);
          imgDiv.addEventListener('click', this.onImgDivClick);

          this.imageslist.appendChild(imgDiv);
        }
      }
      this.getImagesBufferArray();
      this.onWindowResize();
      
    },

    onImgDivClick(event){
      //console.log(Array.from(event.target.parentNode.children).indexOf(event.target));
      for (let i=0; i<event.currentTarget.parentNode.children.length; i++){
        event.currentTarget.parentNode.children[i].style.borderStyle = "none"
      }
      if(event.target !== event.currentTarget){
        event.target.parentNode.style.borderStyle = "solid"
      }else{
        event.target.style.borderStyle = "solid"
      }
      
      this.imageSelected = Array.from(event.target.parentNode.children).indexOf(event.target);
    },

    onUpClicked(event){
      event.target.removeAttribute("aria-describedby");
      document.activeElement.blur();
      if(this.imageSelected > 0)
      {
        this.imageslist.children[this.imageSelected-1].before(this.imageslist.children[this.imageSelected])
        this.imageSelected = this.imageSelected-1;
      }
      this.getImagesBufferArray();
      this.onWindowResize();
    },
    

    onDownClicked(event){
      event.target.removeAttribute("aria-describedby");
      document.activeElement.blur();
      if(this.imageSelected < this.imageslist.children.length-1)
      {
        this.imageslist.children[this.imageSelected + 1].after(this.imageslist.children[this.imageSelected])
        this.imageSelected = this.imageSelected + 1;
      }
      this.getImagesBufferArray();
      this.onWindowResize();
    },

    onRemoveClicked(event){
      event.target.removeAttribute("aria-describedby");
      document.activeElement.blur();
      this.images.splice(this.imageSelected, 1)
      this.imagesInput()
    },

    removeImgAll(){
      this.images = [];
      this.previewCanvasContext.clearRect(0, 0, this.previewcanvasWidth, this.previewcanvasHeight);
    },

    togglePlay() {
      if (this.video.paused || this.video.ended) {
        this.video.currentTime = this.startTime;
        this.video.play();

        this.video.ontimeupdate = () => {
          if(this.video.currentTime >= this.endTime){
            this.video.pause();
          }
        }
      } else {
        this.video.pause();
      }
    },

    restartPlay(){
      this.video.pause();
      this.togglePlay() 
    },

    updateToggleButton() {
      this.playBtn.innerHTML = this.video.paused ? "►" : "❚ ❚";
    },

    trimstartPlay(time){
      clearTimeout(this.startTimeout);
      this.startTimeout = setTimeout(this.trimstartdelayed(time), 250); 
    },

    trimendPlay(time){
      clearTimeout(this.endTimeout);
      this.endTimeout = setTimeout(this.trimenddelayed(time), 250); 
    },

    trimstartdelayed(time){
      this.startTime = time;
      this.updateMaxTokens();
      if(this.video){
        this.video.currentTime = this.startTime;
        this.video.play();

        this.video.ontimeupdate = () => {
          if(this.video.currentTime >= this.endTime){
            this.video.pause();
          }
        }
      }
      
    },

    trimenddelayed(time){
      this.endTime = time;
      this.updateMaxTokens();
      if(this.video){
        this.video.currentTime = this.startTime;
        this.video.play();

        this.video.ontimeupdate = () => {
          if(this.video.currentTime >= this.endTime){
            this.video.pause();
          }
        }
      }
    },

    async generateScript(){
      //console.log("calling server for text generation")
      if (this.isTooFastAction()){
        this.alertMessage="Too many actions, try again later!";
        this.$bvModal.show('alertmodal');
        return;
      }

      this.checkprompt();
      this.updateMaxTokens();

      if(this.inputType=='images' && this.images.length < 3){
        this.promptMessage = 'Upload the requied images before generating the script';
        return;
      }

      if(this.inputType =='video' && !this.file){
        this.promptMessage = 'Upload the requied video before generating the script';
        return;
      }
      
      if (this.prompt !== '' && this.promptMessage == ''){
        this.$store.dispatch("textCompletionTranscriber", {prompt: this.prompt, max_tokens : this.maxVidTokens, router: this.$router});
      } else {
        this.promptMessage = 'No text entered or text contain issues like special characters: `@#$%^&*=[]{}\\|<>/~!';
      }
    },

    onPromptInput(){
      this.promptMessage ='';
      this.checkprompt();
      this.updateMaxTokens();
    },

    updateMaxTokens(){
      let durationCharacters;
      if(this.inputType == "video"){
        durationCharacters = (this.endTime - this.startTime) * 17;  //XXX characters
      } else {
        durationCharacters = this.imgVideoDuration * 17;  //XXX characters
      }
      this.maxVidTokens = Math.floor(this.prompt.length / 4) + Math.floor(durationCharacters / 4);
    },

    onPromptOutput(){
      this.promptMessageOutput = '';
      this.checkpromptOutput();
    },

    onInputType(){
      this.promptMessage ='';
      this.promptMessageOutput = '';
    },

    checkprompt(){
      var format = /[`@#$%^&*=[\]{}\\|<>/~]/;
      if(format.test(this.prompt)){
        this.promptMessage = "Error:These characters are not allowed: `@#$%^&*=[]{}\\|<>/~";
      } 
      else if (this.prompt.length > 500){
        this.promptMessage = `Error: Prompt length should be up to 500 characters`;
      }
      else {
        this.promptMessage ='';
      }
     
    },

    checkpromptOutput(){
      var format = /[`@#$%^&*=[\]{}\\|<>/~]/;
      if(format.test(this.promptOutput)){
        this.promptMessageOutput = "Error:These characters are not allowed: `@#$%^&*=[]{}\\|<>/~";
      } 
      else if (this.promptOutput.length > 2000){
        this.promptMessageOutput = `Error: Script length should be up to 2000 characters`;
      }
      else {
        this.promptMessageOutput ='';
      }
     
    },

  }    

};

</script>

<style scoped>

  @font-face{
    font-family: 'Amiri Regular';
    src: url('../assets/fonts/Amiri-Regular.ttf');
  }
  @font-face{
    font-family: 'Aref Ruqaa Regular';
    src: url('../assets/fonts/ArefRuqaa-Regular.ttf');
  }
  @font-face{
    font-family: 'Lalezar-Regular';
    src: url('../assets/fonts/Lalezar-Regular.ttf');
  }
   @font-face{
    font-family: 'Rakkas Regular';
    src: url('../assets/fonts/Rakkas-Regular.ttf');
  }
  @font-face{
    font-family: 'Reem Kufi Regular';
    src: url('../assets/fonts/ReemKufi-Regular.ttf');
  }
  @font-face{
    font-family: 'Marhey Regular';
    src: url('../assets/fonts/Marhey-Regular.ttf');
  }


  @font-face{
    font-family: 'Cabin Sketch Regular';
    src: url('../assets/fonts/CabinSketch-Regular.ttf');
  }
  @font-face{
    font-family: 'Fontdiner Swanky Regular';
    src: url('../assets/fonts/FontdinerSwanky-Regular.ttf');
  }
  @font-face{
    font-family: 'Freckle Face';
    src: url('../assets/fonts/FreckleFace-Regular.ttf');
  }
  @font-face{
    font-family: 'Gloria Hallelujah';
    src: url('../assets/fonts/GloriaHallelujah.ttf');
  }
  @font-face{
    font-family: 'Lacquer Regular';
    src: url('../assets/fonts/Lacquer-Regular.ttf');
  }

  @font-face{
    font-family: 'Protest Revolution Regular';
    src: url('../assets/fonts/ProtestRevolution-Regular.ttf');
  }
  
  @font-face{
    font-family: 'Rubik Moonrocks Regular';
    src: url('../assets/fonts/RubikMoonrocks-Regular.ttf');
  }
  @font-face{
    font-family: 'Spicy Rice';
    src: url('../assets/fonts/SpicyRice-Regular.ttf');
  }

  canvas {
    border-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: black;
    width: 100%;
    margin-bottom: 4px;
  }

  .imgdivcss {
    display: inline-block;
    vertical-align: middle;
    background: white;
    border-radius: 3px;
  }



</style>