import axios from 'axios';

const API_URL = 'https://speechormanager.univerhome.com/api/fth-api/';
//const API_URL = 'http://192.168.0.190/speechor/api/fth-api/';

const axinstance = axios.create();
axinstance.defaults.timeout = 60000;

class AuthService {
  login(user, retoken) {
    return axinstance
      .post(API_URL, {
        "name":"signIn",
        "param":{
                  "email": user.username,
                  "pass": user.password,
                  "retoken": retoken,
                }
      },
      {
        headers: {
        'Content-Type': 'application/json'
        }
      })
      .then(response => {
        //console.log(response);
        if (response.data.response.status == '200' && response.data.response.result.typocsscloud) {
          localStorage.setItem('blueSky', JSON.stringify(response.data.response.result));   
        } 
        return response.data;  
      });
  }

  sendEmailPassCode(email, retoken){
    return axinstance.post(
                      API_URL, 
                      {
                        "name":"sendEmailPassCode",
                        "param":{
                                  "email": email,
                                  "retoken": retoken,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  verifyPassCode(code, email, retoken){
    return axinstance.post(
                      API_URL, 
                      {
                        "name":"verifyPassCode",
                        "param":{
                                  "code": code,
                                  "email": email,
                                  "retoken": retoken,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  resetPass(code, email, password){
    return axinstance.post(
                      API_URL, 
                      {
                        "name":"resetPass",
                        "param":{
                                  "code" : code,
                                  "email" : email,
                                  "password" : password
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  SignUp(fullname,email, country, phone, pass, retoken, referrer){
    return axinstance.post(
                      API_URL, 
                      {
                        "name":"SignUp",
                        "param":{
                                  "fullname": fullname,
                                  "email": email,
                                  "country": country,
                                  "phone": phone,
                                  "password": pass,
                                  "retoken": retoken,
                                  "referrer": referrer,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  verifyEmailSignupCode(code, email, retoken){
    return axinstance.post(
                      API_URL, 
                      {
                        "name":"verifyEmailSignupCode",
                        "param":{
                                  "code": code,
                                  "email": email,
                                  "retoken": retoken,
                                }
                      },
                      {
                        headers: {
                        'Content-Type': 'application/json'
                        }
                      } 
                      );
  }

  logout() {
    localStorage.removeItem('blueSky');
    document.getElementById("app").style.paddingLeft = "0px";
  }

}

export default new AuthService();