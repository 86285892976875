<template>
  <div id="app">
    <div class="page" v-if="getSpinner">
      <b-spinner class="spinner" :variant="'primary'" :key="'primary'"></b-spinner>
    </div>
    <div id="nav" v-if="currentUser">
    <top-header></top-header>
    </div>
    <router-view />
    <side-bar v-if="currentUser"></side-bar>
    <app-footer v-if="currentUser"></app-footer>
    
  </div>
</template>
<script>
import TopHeader from "@/components/TopHeader.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
    };
  },
  components: { 
    "top-header": TopHeader,
    'side-bar': SideBar,
    'app-footer': Footer
  },
  mounted() {
  
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.blueSky;
    },

    ...mapGetters(["getSpinner"])
  }
};
</script>

<style>
.badge {
  vertical-align: super;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #323333 /*#292936*/;
  color: white;
  font-size: 0.75rem;
  position: relative;
  padding-bottom: 20px;
  min-height: 100vh;
  display: block;
}
#nav {
  width: 100%;
  padding: 0px;
  a {
    font-weight: lighter;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #0d0e4f;
    }
  }
}
.page {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 25;
  width: 100%;
  height: 100%;
  text-align: center;
}
.spinner {
  z-index: 26;
  position: relative;
  top: 50%;
}

.custom-control-label {
    padding-top: 4px;
  }
</style>
