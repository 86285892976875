<template>
	<b-card header-tag="header" bg-variant="dark" text-variant="light">
      	<template #header>
        	<p class="mb-0 text-secondary">Available Voices <b-badge v-b-tooltip.bottom title="Select a Language based on your Script Language, then select a voice. Please note: in the Settings bellow select the same language" class="mb-0 text-secondary" pill variant="light"><span style="color: #ffffff;"></span>?</b-badge></p>
      	</template>
		<!--card-body-->
		<b-alert v-if="errorMessage" variant="danger"  show>{{ errorMessage }}</b-alert>
		<b-form class="form-lef">
			<b-form-group id="input-pack-gr" class="mt-1" label-for="input-pack">
		        <b-form-select
		        size="sm"
		        id="input-pack"
		        v-model="selectedLang"
		        :options="getLangOptions"
		        @input="getFilteredVoices"
		        
		        >
		        <template #first>
		        	<b-form-select-option :value="null" disabled>-- Select Language --</b-form-select-option>
		      	</template>
		    	</b-form-select>
		    </b-form-group>
		    <b-form-group id="input-group-tech">
		    	<b-form-radio-group 
		    	id="radio-group-tech" 
		    	v-model="selectedGender"
		    	:options="genderOptions"
		    	v-on:input="getFilteredVoices"
		    	name="some-radios"
		    	button-variant="outline-primary"
		    	size="sm"
		    	buttons 
		    	
		    	>
		        </b-form-radio-group>
		    </b-form-group>
		    <p v-if="available">Available actors:</p>
		    <b-form-group id="input-group-voice" class="scroll-voices">
		    	<b-form-radio-group
		    	@input="setSelectedActor"
		    	size="sm"
		    	id="radio-group-voice" 
		    	v-model="selectedActor"
		    	name="radio-sub-component"
		    	buttons
		    	button-variant="outline-primary"
    			stacked 
		    	
		    	>
		    	<template v-for="option in getAvailableVoices" >
		    	
		    	  <b-form-radio :value="[option.value, option.text]" :key="option.text">
			        {{ option.text }}
			        <b-badge v-b-tooltip.left title="Actor sample"  variant="success" @click="badge(option.value)"><b-icon icon="play-fill" font-scale="1" aria-hidden="true"></b-icon></b-badge>
			      </b-form-radio>
		    	
			    </template>
			    </b-form-radio-group>
		    </b-form-group>	    
		</b-form>
	   
    </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	props: {
	    message: {
	      type: String,
	      required: false,
	    },

	    
	},
	data(){
	 	return{
	 		clicksTimer:[],
	 		available:false,
	 		audio: undefined,
	 		playing: false,
	 		optionListen:'',
	 		selectedActor: ['',''],
	 		selectedGender: 'MALE',
	 		selectedLang: null,
	 		genderOptions:[
	 		 		{text: "male", value: "MALE"},
	 		 		{text: "female", value: "FEMALE"}
	 		]	 
		}
	},
	mounted() {
		this.$store.dispatch("initialize");
		this.$store.dispatch("getActorsCount", {router: this.$router});
	},
	computed: {
	    ...mapGetters(["getLangOptions", "getAvailableVoices"]),

	    errorMessage() {
	      return this.message;
	    },

	},
	methods: {

		setSelectedActor(){
	        this.$store.dispatch("setSelectedActor",{name: this.selectedActor[0]});
	        this.$emit('voice-input', true);
	    },
	    
  		getFilteredVoices() {
  			this.selectedActor = ['',''];
        	this.$store.dispatch("getVoices", { language: this.selectedLang, gender: this.selectedGender, myJson: this.$store.state.xmyJson });
        	this.available = true;
      	},

      	
      	badge(val){
      		var fileName = val.replace(/-/g, '');
      		var file = 'https://speechormanager.univerhome.com/uploads/samples/'+fileName+'.mp3';
      		this.audio = new Audio(file);
      		if (this.playing) return;
            this.audio.play().then(() => this.playing = true);
        	this.audio.addEventListener('ended', () => {
  			this.playing = false
			});
      	}
 	}
	
	 
}

</script>

<style scoped>
	.form-lef{
		text-align: left;
	}
	.scroll-voices{
		overflow-x: hidden;
		overflow-y: auto;
		max-height:150px;
	}
</style>