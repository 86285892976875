<template>
 <b-container fluid> 
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> AI Image <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
    <widget-img></widget-img>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-row no-gutters>
            <b-col>
                <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Image-settings</p>
                  </template>
                  <b-alert v-if="message" variant="danger"  show>{{ message }}</b-alert>
                  <b-alert v-if="messageprompt" variant="danger" size="sm"  show>{{ messageprompt }}</b-alert>
                  
                  <div>
                    <label for="format" class="text-secondary">Generate Image from: <b>{{format}}</b></label>
                    <b-form-group id="format" v-slot="{ ariaDescribedby }">
                      <b-form-radio class="d-inline-block mr-3" v-model="format" :aria-describedby="ariaDescribedby" name="format-radios" value="prompt" @input="langinput">Prompt</b-form-radio>
                      <b-form-radio class="d-inline-block" v-model="format" :aria-describedby="ariaDescribedby" name="format-radios" value="image" @input="langinput">Image</b-form-radio>
                    </b-form-group>
                  </div>

                  <div class="mt-2 mb-2">
                  <!-- Styled -->

              <!-- ERRORS -->
                    <div class="alert alert-danger" v-show="errors.any('submitfile')&&(format=='image')">
                      <div v-if="errors.has('submitfile.file')">
                        {{ errors.first('submitfile.file') }}
                      </div>
                    </div>

                    <b-form class="mb-2" data-vv-scope="submitfile" v-show="format=='image'">
                      <b-form-file
                      v-model="file"
                      v-validate="'required|image|size:4000|ext:png'"
                      data-vv-as="file"
                      :state="Boolean(file)"
                      placeholder="Image must be PNG and Square and <4MB"
                      drop-placeholder="Drop file here..."
                      name = "file"
                      @input="langinput"
                    ></b-form-file>
                    </b-form>
                    <b-button size="sm" @click="file = null && langinput" :disabled="!file" v-show="format=='image'">Remove Image</b-button>
                  </div>

                  <div class="mt-2 mb-2" v-show="format=='prompt'">
                    <b-form-textarea
                      class="border-secondary"
                      id="imgprompt"
                      size="sm"
                      placeholder="Enter your prompt..."
                      rows="3"
                      v-model="prompt"
                      @input="editdesCount"
                      maxlength ="1000"
                      required
                    ></b-form-textarea>
                    <p id="promptCounter" class="my-0 text-secondary"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon> {{count}}/1000</p>
                  </div>

                </b-card>
            </b-col>
          </b-row>
          <b-row no-gutters v-show="format=='prompt'">
            <b-col xs="6" md="4" class="p-1 pt-1">
                  <b-form-select
                    class="w-100 mt-2"
                    size="sm"
                    id="preslang"
                    v-model="selectedImgSize"
                    :options="SizeOptions"
                    required
                    @input="langinput"
                    >
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Size --</b-form-select-option>
                    </template>
                  </b-form-select>
            </b-col>
            <b-col xs="6" md="8" class="p-1">
              <b-button 
                  class="w-100 mt-2" 
                  size="md"
                  squared 
                  id="submit-1" 
                  variant="warning"
                  @click.prevent="imgGenerate"
                  >Generate</b-button>
              </b-col>
              
          </b-row>
          <b-row no-gutters v-show="format=='image'">
            <b-col xs="6" md="4" class="p-1 pt-1">
                  <b-form-select
                    class="w-100 mt-2"
                    size="sm"
                    id="preslang"
                    v-model="selectedExtImgSize"
                    :options="varSizeOptions"
                    required
                    @input="langinput"
                    >
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Size --</b-form-select-option>
                    </template>
                  </b-form-select>
            </b-col>
            <b-col xs="6" md="8" class="p-1">
              <b-button 
                  class="w-100 mt-2" 
                  size="md"
                  squared 
                  id="submit-1"
                  variant="primary"
                  @click.prevent="variateExternalImage"
                  ><b-icon icon="brush-fill" variant="light" font-scale="1" aria-hidden="true"> </b-icon> Generate</b-button>
              </b-col>
              
          </b-row>
        </b-col>
      </b-row>
      <b-row class="border-top border-secondary">
        <table-img></table-img>
      </b-row>

      <!-- Info modal: audio created succesfully -->
      <b-modal 
        id="imgCreatedModal"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div v-if="pptxPrcessing" style="text-align: center;">
            <p>Image is processing ...</p>
            <div class="text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
          </div> 
          <div v-else-if="pptxsaveSuccess" style="text-align: center;">
              <p>Image created and saved successfully!</p>
              <b-icon icon="check2-circle" font-scale="3" animation="throb" variant="success"></b-icon>
              <b-button class="mt-3" block @click="$bvModal.hide('imgCreatedModal')">Close</b-button>
          </div>
          <div v-else-if="pptxsaveFailed[0]" style="text-align: center;">
              <p>{{pptxsaveFailed[1]}}</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
              <b-button class="mt-3" block @click="$bvModal.hide('imgCreatedModal')">Close</b-button>
          </div>
          
        </b-modal>
      
 </b-container>
</template>

<script>
 import IMGWidget from "@/components/ImgWidgets.vue";
 import IMGTable from "@/components/ImgTable.vue";

export default {
  components: { 
    'widget-img': IMGWidget ,
    'table-img': IMGTable
  },

  data() {
    return {
      clicksTimer:[],
      regex:/[`@#$%^&*=[\]{}"\\|<>~]/,
      message:'',
      messageprompt:'',
      prompt:'',
      format:"prompt",
      count: 0,
      file:null,
      selectedImgSize:null,
      selectedExtImgSize:null,
      SizeOptions : [
                    {text: "512x512", value:"512_512", disabled: false},
                    {text: "1024x1024", value:"1024_1024", disabled: false},
                    {text: "Standard 1024x1024", value:"st_1024_1024", disabled: false},
                    {text: "Standard 1024x1792", value:"st_1024_1792", disabled: false},
                    {text: "Standard 1792x1024", value:"st_1792_1024", disabled: false},
                    {text: "HD 1024x1024", value:"hd_1024_1024", disabled: false},
                    {text: "HD 1024x1792", value:"hd_1024_1792", disabled: false},
                    {text: "HD 1792x1024", value:"hd_1792_1024", disabled: false}
                  ],
      varSizeOptions : [
                    
                    {text: "512x512", value:"512_512", disabled: false},
                    {text: "1024x1024", value:"1024_1024", disabled: false},
                  ],
    };
  },
 
  computed: {

      pptxPrcessing(){
        var pptxshowThis;
        if (this.$store.state.pptxprocessingSpinner == true) 
        {
          pptxshowThis = true;
        } 
        else 
        {
          pptxshowThis = false;
        }
        return pptxshowThis;
      },

      pptxsaveSuccess(){
        var pptxsaveSuccess;
        if (this.$store.state.pptxsuccessSpinner == true) 
        {
          pptxsaveSuccess = true;
        } 
        else 
        {
          pptxsaveSuccess = false;
        }
        return pptxsaveSuccess;
      },

      pptxsaveFailed(){
        var pptxsavefailed;
        if (this.$store.state.pptxfailedSpinner[0] == true) 
        {
          pptxsavefailed = [true,this.$store.state.pptxfailedSpinner[1]] 
        } 
        else 
        {
          pptxsavefailed = [false,''];
        }
        return pptxsavefailed;
      },
 
  },

 
  methods: {

    isTooFastAction(){
      var currentClickTime;
      if(!Date.now) {
        Date.now = function(){ return new Date().getTime();};
        currentClickTime = Date.now();
      }else{
        currentClickTime = Date.now();
      }
      if(this.clicksTimer.length == 0){
        this.clicksTimer.push(currentClickTime)
        return false;
      }
      else {
        if (currentClickTime-this.clicksTimer[this.clicksTimer.length-1]<5000){
          this.clicksTimer.push(currentClickTime);
          this.clicksTimer.splice(this.clicksTimer.length-2,1);
          return true;
        }
        this.clicksTimer.push(currentClickTime);
        this.clicksTimer.splice(this.clicksTimer.length-2,1);
        return false;
      }  
    },

    imgGenerate(){

      if (this.isTooFastAction()){
        this.message = "Too many actions, try again later!";
        return;
      }
     
      if(this.selectedImgSize!==null && this.prompt!=='' && this.messageprompt == ''){
        this.$store.dispatch("generateImage", 
        {
          router: this.$router,
          size: this.selectedImgSize,
          prompt: this.prompt,
        });
        this.$bvModal.show('imgCreatedModal');

      } else {
        this.message = "All fields are required, and should be filled correctly!";
      }
      
    },

    variateExternalImage(){

      if (this.isTooFastAction()){
        this.message = "Too many actions, try again later!";
        return;
      }

      if(this.selectedExtImgSize !== null && this.file !== null){

        if( this.file.size < 4_000_001 && this.file.type == 'image/png'){
          this.$validator.validateAll('submitfile').then(
            async (isValid) => {
              if (isValid) {
                const {width, height } = await this.getImageDimensions();
                if (width == height){
                  this.$store.dispatch("variateExternalImage", {router: this.$router, outsize:this.selectedExtImgSize, file : this.file});
                  this.$bvModal.show('imgCreatedModal');
                }else{
                  this.message = 'The Image should be square for example 512x512 px';
                }
              } else {
                this.message = 'Please fix these errors!';
               
              }
          }, 
          (error) => {
                  this.message = error;
          });
        } else {
          this.message = "Size must be <4MB, image must be PNG ";
        }
      }
      else {
        this.message = "All shown fields are required, and should be filled correctly!";
      }

      
    },
    
    async getImageDimensions() {
      let img = new Image();
      img.src = URL.createObjectURL(this.file);
      await img.decode();
      let width = img.width;
      let height = img.height;
      URL.revokeObjectURL(img.src);
      return {width, height};
    },

  

    langinput(){
      this.message='';
    },

    editdesCount(){
      this.message='';
      var textValue = document.getElementById("imgprompt").value;
      if(this.regex.test(textValue)){
        this.messageprompt = "Description contains disallowed characters: `@#$%^&*=[]{}\"\\|<>~";
      } else {
        this.messageprompt ='';
      }
      this.count = textValue.length;
      var element = document.getElementById("promptCounter");
      if (textValue.length > 5500 ) {
        element.classList.remove("text-secondary");
        element.classList.add("text-danger");
      } else{
        element.classList.remove("text-danger");
        element.classList.add("text-secondary");
      }
    },

   
  }    

};

</script>

<style scoped>
  .alert-danger {
    height: 31px;
  }
  .alert {
    padding: 0.4rem 1.25rem;
  }
</style>