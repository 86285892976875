<template>
  <div class="w-100 pt-0 pl-3 pr-3"> 
    <b-row class="d-flex justify-content-around my-4">
      <b-col md="2" class="my-1 ">
          <b-button class="w-100" size="sm" square :disabled="refreshDelay" @click="refreshRecords"><span v-show="refreshSpinner" class="spinner-border spinner-border-sm "></span> Refresh</b-button>
      </b-col>
      <b-col md="3" class="my-1">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="3" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="7" md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        dark
        small
        stacked="md"
        ref="tableRef"
        :items="getVideoRecordedItems"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      > 
        <template #cell(actions)="row">
          <b-button size="sm"  variant="success" @click.prevent="downloadRecord(row.item)" class="mr-1 mb-1" :disabled="downloadDelay || row.item.status !== 'completed'"><b-icon icon="download" variant="light" font-scale="1" aria-hidden="true"></b-icon>
           </b-button>
        </template>

      </b-table>
  </b-row>

  <!-- Info modal: audio created succesfully -->
      <b-modal 
        id="videoDownloadMess"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div v-if="videodownloaderr" style="text-align: center;">
              <p>File not found! Files older than 1hour was deleted</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
              <b-button class="mt-3" block @click.prevent="modalClosed">Close</b-button>
          </div>
          
        </b-modal>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
       
    },
    data() {
      return {
        fields: [ 
          { key: 'rId', label: 'ID'},
          { key: 'start', label: 'Start'},
          { key: 'end', label: 'End'},
          { key: 'duration', label: 'Duration'},
          { key: 'status', label: 'Status'},
          { key: 'actions', label: 'Download'},
          { key: 'date', label: 'Date(UTC)', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [  { value: 10, text: "10  per page" },
                { value: 25, text: "25  per page" },
                { value: 50, text: "50  per page" },
                { value: 100, text: "Show a lot" }
               ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        userId:'' ,
        file:'',
        refreshDelay: false,
        downloadDelay: false
       
      }
    },
    computed: {
       ...mapGetters(["getVideoRecordedItems"]),

       videodownloaderr(){
        var imgerr;
        if (this.$store.state.videodownloadError == true) 
        {
          this.$bvModal.show('videoDownloadMess');
          imgerr = true;
        } 
        else 
        {
          imgerr = false;
        }
        return imgerr;
      },

      refreshSpinner(){
        return this.$store.state.showSpinner;
      }

      
    },

    mounted() {
      this.$store.dispatch("getVideoRecords", {router: this.$router});    
    },
    
    updated(){
      this.totalRows = this.$store.state.videoRecords.length;
      this.userId = this.$store.state.currentUId;
    },

    methods: {
      refreshRecords(){
        this.delayNextRefresh();
        this.$store.dispatch("getVideoRecords", {router: this.$router});
      },

      delayNextRefresh(){
        this.refreshDelay = true;
        setTimeout(()=>{
          this.refreshDelay =false;
        }, 45000);
      },

      downloadRecord(item){
        this.delayNextDownload();
        this.file = item.rId;
        var fileName = this.file+'.zip';
        this.$store.dispatch("downloadVideoFile", {file : fileName});
      },

      delayNextDownload(){
        this.downloadDelay = true;
        setTimeout(()=>{
          this.downloadDelay = false;
        }, 7000);
      },

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      modalClosed(){
        this.$store.dispatch("initVideoDownloadErr");
        this.$bvModal.hide('videoDownloadMess');
      },

    }
  }

</script>

<style scoped>
</style>