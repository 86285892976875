var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-1","title":"Sidebar","bg-variant":"dark","width":"220px","no-header":"","no-slide":"","no-close-on-route-change":""},on:{"shown":_vm.addLeftPadding,"hidden":_vm.cancelLeftPadding}},[_c('b-navbar-brand',{staticClass:"px-3 py-3 m-0 alignbrand w-100",attrs:{"href":"#"}},[_c('img',{staticClass:"d-inline-block align-center",attrs:{"src":require("../assets/logo.png"),"width":"125","height":"50","alt":"Speechor"}})]),[(_vm.isVideoSubscriber)?_c('b-navbar',{attrs:{"toggleable":"","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{staticClass:"nav-txt",attrs:{"tag":"div","to":"/Transcriber"}},[_vm._v("Video Transcriber "),_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("new")])],1),_c('b-navbar-toggle',{staticClass:"tog-small",attrs:{"target":"navbar-toggle-video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up","font-scale":"0.7"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down","font-scale":"0.7"}})]}}],null,false,700366023)}),_c('b-collapse',{attrs:{"id":"navbar-toggle-video","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/Transcriber"}},[_vm._v("Transcriber UI")]),_c('b-nav-item',{attrs:{"to":"/Transcriber_Billing"}},[_vm._v("Transcriber Billing")]),_c('b-nav-item',{attrs:{"to":"/Transcriber_Tutorial"}},[_vm._v("Transcriber Tutorial")])],1)],1)],1):_vm._e()],(!_vm.isVideoSubscriber)?_c('div',[_c('ul',[_c('router-link',{attrs:{"tag":"li","to":"/Subscription_Video_Transcriber"}},[_vm._v("Video Transcriber "),_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("new")])],1)],1)]):_vm._e(),[_c('b-navbar',{attrs:{"toggleable":"","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{staticClass:"nav-txt",attrs:{"tag":"div","to":"/Imagine"}},[_vm._v("AI Image")]),_c('b-navbar-toggle',{staticClass:"tog-small",attrs:{"target":"navbar-toggle-img"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up","font-scale":"0.7"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down","font-scale":"0.7"}})]}}])}),_c('b-collapse',{attrs:{"id":"navbar-toggle-img","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/Imagine"}},[_vm._v("Image UI")]),_c('b-nav-item',{attrs:{"to":"/ImagineBoost"}},[_vm._v("Image Boosters")]),_c('b-nav-item',{attrs:{"to":"/ImagineTutorial"}},[_vm._v("Image Tutorial")])],1)],1)],1)],[_c('b-navbar',{attrs:{"toggleable":"","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{staticClass:"nav-txt",attrs:{"tag":"div","to":"/Speechor"}},[_vm._v("Text to Speech")]),_c('b-navbar-toggle',{staticClass:"tog-small",staticStyle:{"padding":"2px 4px"},attrs:{"target":"navbar-toggle-Speechor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up","font-scale":"0.7"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down","font-scale":"0.7"}})]}}])}),_c('b-collapse',{attrs:{"id":"navbar-toggle-Speechor","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/Speechor"}},[_vm._v("Text to Speech UI")]),_c('b-nav-item',{attrs:{"to":"/MusicLibrary"}},[_vm._v("Music Library")]),_c('b-nav-item',{attrs:{"to":"/Tutorial"}},[_vm._v("TTSpeech Tutorial")]),_c('b-nav-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.getBalance[1] !== '0' || _vm.getBoost),expression:"getBalance[1] !== '0' || getBoost"}],attrs:{"to":"/AddFunds"}},[_vm._v("Characters Boosters")])],1)],1)],1)],[_c('b-navbar',{attrs:{"toggleable":"","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{staticClass:"nav-txt",attrs:{"tag":"div","to":"/Presentor"}},[_vm._v("AI Presentation")]),_c('b-navbar-toggle',{staticClass:"tog-small",attrs:{"target":"navbar-toggle-Dalle2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up","font-scale":"0.7"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down","font-scale":"0.7"}})]}}])}),_c('b-collapse',{attrs:{"id":"navbar-toggle-Dalle2","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/Presentor"}},[_vm._v("Presentation UI")]),_c('b-nav-item',{attrs:{"to":"/PresBoost"}},[_vm._v("Presentation Boosters")]),_c('b-nav-item',{attrs:{"to":"/PresTutorial"}},[_vm._v("Presentation Tutorial")])],1)],1)],1)],[_c('b-navbar',{attrs:{"toggleable":"","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{staticClass:"nav-txt",attrs:{"tag":"div","to":"/AIgenerator"}},[_vm._v("AI Text")]),_c('b-navbar-toggle',{staticClass:"tog-small",attrs:{"target":"navbar-toggle-ChatGPT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up","font-scale":"0.7"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down","font-scale":"0.7"}})]}}])}),_c('b-collapse',{attrs:{"id":"navbar-toggle-ChatGPT","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/AIgenerator"}},[_vm._v("AI Text UI")]),_c('b-nav-item',{attrs:{"to":"/creditsbooster"}},[_vm._v("AI Text Boosters")]),_c('b-nav-item',{attrs:{"to":"/AItexttutorial"}},[_vm._v("AI Text Tutorial")])],1)],1)],1)],[_c('b-navbar',{attrs:{"toggleable":"","type":"dark","variant":"dark"}},[_c('b-navbar-brand',{staticClass:"nav-txt",attrs:{"tag":"div","to":"/Affiliate-Dashboard"}},[_vm._v("Affiliate Program")]),_c('b-navbar-toggle',{staticClass:"tog-small",attrs:{"target":"navbar-toggle-affiliate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var expanded = ref.expanded;
return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up","font-scale":"0.7"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down","font-scale":"0.7"}})]}}])}),_c('b-collapse',{attrs:{"id":"navbar-toggle-affiliate","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"to":"/Affiliate-Dashboard"}},[_vm._v("Affiliate Dashboard")]),_c('b-nav-item',{attrs:{"to":"/Affiliate-Tutorial"}},[_vm._v("Affiliate Guide")])],1)],1)],1)],_c('div',[_c('ul',[_c('router-link',{attrs:{"tag":"li","to":"/SpeechorBot"}},[_vm._v("Speechor Bot "),_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("new")])],1),_c('router-link',{attrs:{"tag":"li","to":"/Settings"}},[_vm._v("Account Settings")])],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }