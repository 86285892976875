<template>
  <div style="width: 100%">
    <div style="text-align:center; font-size: 0.9em;"><span style="color: #999999;">{{new Date(trimStart*1000).toISOString().slice(11,22)}} >> {{new Date(trimEnd*1000).toISOString().slice(11,22)}}</span></div> 
    <div style="text-align:center; font-size: 0.9em;"><span style="color: #999999;">[Duration: {{new Date((trimEnd-trimStart)*1000).toISOString().slice(11,22)}}]</span></div>
    <canvas
      class="mb-0"
      ref="sliderCanvas"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      style="user-select: none;"
    />
    <div class="mb-4" style="text-align: center;">
      <b-button v-b-tooltip.bottom title="trim 60 seconds" class="mr-1" size="sm" squared  :disabled="storedFrames.length==0" @click.prevent="handleDurationBtn(60)">60sec</b-button>
      <b-button v-b-tooltip.bottom title="trim 90 seconds" class="mr-1" size="sm" squared  :disabled="storedFrames.length==0" @click.prevent="handleDurationBtn(90)">90sec</b-button>
    </div>
    
  </div>
</template>
<script>

const Handles = Object.freeze({
  START_HANDLE: 'START_HANDLE',
  END_HANDLE: 'END_HANDLE',
  CENTER_HANDLE: 'CENTER_HANDLE',
});

export default {
  name: 'VideoTrimmer',
  props: {
    frames: {
      type: Array,
      required: true,
    },

    videoDuration: {
      type: Number,
      required: true,
    },
    defaultTrim: {
      type: Object,
      default: null,
    },
    minTrimDuration: {
      type: Number,
      default: 60,
    },
    maxTrimDuration: {
      type: Number,
      default: 90,
    },
    inputTypeTrim: {
      type: String,
      default: 'video'
    }
  },
  data () {
    return {
      startHandlePos: 14,
      endHandlePos: 800,
      timeHandlePos: 0,
      canvasWidth: 800,
      canvasHeight: 104,
      frameWidth: 80,
      handleWidth: 14,
      canvasContext: null,
      framesCanvas: null,
      trimStart: 0,
      trimEnd: 0,
      selectedElement: null,
      centerX:0,
      centerY:0,
      radius:0,
      distanceFromCenerToHandlers:0,
      //btn_togle_sidebar: null,
      startTimeoutx:'',
      
    };
  },
  computed: {
    durationPositionRatio () {
      return this.videoDuration / (this.canvasWidth - (2*this.handleWidth));
    },
    positionDurationRatio () {
      return (this.canvasWidth - (2*this.handleWidth)) / this.videoDuration;
    },
    toggleClicked(){
        return this.$store.state.toggleClicked;
    },
    storedFrames(){
      return this.$store.state.frames;
    }
  },
  watch: {
    frames (newValue) {
      if (newValue.length == 0) {
        //console.log('trim to zero');
        // Clear the canvas
        this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.trimStart = 0;
        this.trimEnd = 0;
      } else {
        this.drawAgain();
      }
      
    },
  
    defaultTrim (newValue) {
      const { start, end } = newValue;

      this.trimStart = start;
      this.startHandlePos = (start * this.positionDurationRatio)+this.handleWidth;

      this.trimEnd = end;
      this.endHandlePos = (end * this.positionDurationRatio)+this.handleWidth;
    },

    toggleClicked(){
      //console.log("toggleClicked called", newValue);
      setTimeout(()=>{this.onWindowResize();}, 250); 
    },
    
  },
  async mounted () {

    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('mousemove', this.handleMouseMove);
    
    this.setCanvasWidth();
    this.handleDurationChange();

    // Get the canvas element and its context
    this.canvasContext = this.$refs.sliderCanvas.getContext('2d');

    // Set the canvas width and height
    this.$refs.sliderCanvas.width = this.$refs.sliderCanvas?.offsetWidth;
    this.$refs.sliderCanvas.height = this.canvasHeight;

    // Draw the frames
    /* this.drawFrames().then(() => {
      // Draw the offscreen canvas onto the main canvas
      this.canvasContext.drawImage(this.framesCanvas, 0, 0);

      // Draw the initial slider
      this.drawSlider();
    });*/
  },

  beforeUnmount () {
  // Remove the window resize and mousemove event listeners
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('mousemove', this.handleMouseMove);
  },

  methods: {

    async drawAgain () {
      if(this.inputTypeTrim !== 'video'){
        return;
      }
      this.setCanvasWidth();
      this.handleDurationChange();

      // Set the canvas width and height
      this.$refs.sliderCanvas.width = this.$refs.sliderCanvas?.offsetWidth;
      this.$refs.sliderCanvas.height = this.canvasHeight;
      // Set the canvas style width and height
      this.$refs.sliderCanvas.style.width = 3 * this.$refs.sliderCanvas?.offsetWidth;
      this.$refs.sliderCanvas.style.height = 3 * this.canvasHeight;

      // Draw the frames
      this.drawFrames().then(() => {
        // Draw the offscreen canvas onto the main canvas
        this.canvasContext.drawImage(this.framesCanvas, 0, 0);

        // Draw the initial slider
        this.drawSlider();
      });
    },
    async onWindowResize () {
      if(this.inputTypeTrim !== 'video'){
        return;
      }

      this.setCanvasWidth();

      // Get the canvas element and its context
      this.canvasContext = this.$refs.sliderCanvas ? this.$refs.sliderCanvas.getContext('2d') : null;

      if (!this.canvasContext) {
        return;
      }

      // Set the canvas width and height
      this.$refs.sliderCanvas.width = this.canvasWidth;
      this.$refs.sliderCanvas.height = this.canvasHeight;
      // Set the canvas style width and height
      this.$refs.sliderCanvas.style.width = 3 * this.canvasWidth;
      this.$refs.sliderCanvas.style.height = 3 * this.canvasHeight;

      await this.$nextTick();

      // Calculate the new handle positions based on the current trim duration
      const durationPositionRatio = this.videoDuration / (this.canvasWidth - (2*this.handleWidth));
      this.startHandlePos = (this.trimStart / durationPositionRatio)+this.handleWidth;
      this.endHandlePos = (this.trimEnd / durationPositionRatio)+this.handleWidth;

      // Draw the frames
      this.drawFrames().then(() => {
      // Draw the offscreen canvas onto the main canvas
        this.canvasContext.drawImage(this.framesCanvas, 0, 0);

        // Draw the initial slider
        this.drawSlider();
      });
    },
    async drawFrames () {
      // Create an offscreen canvas
      this.framesCanvas = document.createElement('canvas');

      this.framesCanvas.width = this.canvasWidth;
      this.framesCanvas.height = this.canvasHeight;

      this.framesCanvas.style.width = 3 * this.canvasWidth;
      this.framesCanvas.style.width = 3 * this.canvasHeight;

      const offscreenContext = this.framesCanvas.getContext('2d');

      this.framesCanvas.fillStyle = '#000000';

      // Load all frames and draw them on the offscreen canvas
      await Promise.all(
        this.frames.map((src) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
          });
        })
      ).then((frames) => {
        let x = this.handleWidth;
        frames.forEach((img) => {
          // Finding the new width and height based on the scale factor
          let newWidth = this.frameWidth;
          let newHeight = (this.frameWidth * img.height) / img.width;

          // get the top left position of the image
          // in order to center the image within the framesCanvas
          let y = this.canvasHeight / 2 - newHeight / 2;

          offscreenContext.drawImage(img, x, y, newWidth, newHeight);
          x += this.frameWidth;
          
        });
      });
    },
    async drawSlider () {
      // Clear the canvas
      this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Draw the offscreen canvas onto the main canvas
      this.canvasContext.drawImage(this.framesCanvas, 0, 0);

      // Add a white filter over the non-intersecting areas
      this.updateGlobalCompositeOperation();

      this.canvasContext.shadowOffsetX = '0px';
      this.canvasContext.shadowOffsetY = '4px';
      this.canvasContext.shadowBlur = 4;
      this.canvasContext.shadowColor = 'rgba(253, 188, 4, 0.31)';

      // Draw handle 1
      this.canvasContext.fillStyle = '#fc7f03';
      this.canvasContext.fillRect(
        this.startHandlePos - this.handleWidth,
        0,
        this.handleWidth,
        this.framesCanvas.height
      );
      // Set the font and alignment for the text
      this.canvasContext.font = 'bold 12px Rubik';
      this.canvasContext.textAlign = 'center';

      // Draw the '|' symbol in the middle of the rectangle
      this.canvasContext.fillStyle = '#ffffff';
      this.canvasContext.fillText(
        '|',
        this.startHandlePos - this.handleWidth / 2,
        this.framesCanvas.height / 2 + 8
      );

      // Draw handle 2
      this.canvasContext.fillStyle = '#fc7f03';
      this.canvasContext.fillRect(
        this.endHandlePos,
        0,
        this.handleWidth,
        this.framesCanvas.height
      );
      // Set the font and alignment for the text
      this.canvasContext.font = 'bold 12px Rubik';
      this.canvasContext.textAlign = 'center';

      // Draw the '|' symbol in the middle of the rectangle
      this.canvasContext.fillStyle = '#ffffff';
      this.canvasContext.fillText(
        '|',
        this.endHandlePos + this.handleWidth / 2,
        this.framesCanvas.height / 2 + 8
      );

      //draw slider circle
      this.centerX = this.startHandlePos + ((this.endHandlePos - this.startHandlePos) / 2)
      this.centerY = 5;
      this.radius = 5;

      this.canvasContext.beginPath();
      this.canvasContext.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI, false);
      this.canvasContext.fillStyle = '#eb4c34';
      this.canvasContext.fill();
      this.canvasContext.lineWidth = 1;
      this.canvasContext.strokeStyle = '#ffffff';
      this.canvasContext.stroke();

      // Set the font and alignment for the text
      this.canvasContext.font = 'bold 14px Rubik';
      this.canvasContext.textAlign = 'center';

      // Draw the '>' symbol in the middle of the rectangle
      this.canvasContext.fillStyle = '#ffffff';
      this.canvasContext.fillText(
        '.',
        this.startHandlePos + ((this.endHandlePos - this.startHandlePos) / 2),
        5.5
      );

    },

    handleDurationBtn(time){
      if(time > this.videoDuration){
        //console.log("returned time");
        return;
      }
      this.startHandlePos = 0+this.handleWidth;
      this.trimStart = 0;
      this.$emit('trim-start', this.trimStart);

      this.endHandlePos = this.positionDurationRatio * time +this.handleWidth;
      this.trimEnd = time;
      this.$emit('trim-end', this.trimEnd);

      // Redraw the slider using requestAnimationFrame
      requestAnimationFrame(() => {
        this.drawSlider();
      });
    },

    updateHandles (mouseX) {
      // Move the closest handle to the mouse
      if (this.selectedElement === Handles.START_HANDLE) {
        const currentVideoDuration = this.durationPositionRatio * (this.endHandlePos - mouseX);
        if (currentVideoDuration < this.minTrimDuration || currentVideoDuration > this.maxTrimDuration) {
          return;
        }
        this.startHandlePos = mouseX;
        this.trimStart = this.durationPositionRatio * (mouseX - this.handleWidth) > 0 ? this.durationPositionRatio * (mouseX-this.handleWidth) : 0;
        this.$emit('trim-start', this.trimStart);
      } 

      else if (this.selectedElement === Handles.END_HANDLE) {
        const currentVideoDuration = this.durationPositionRatio * (mouseX - this.startHandlePos);
        if (currentVideoDuration < this.minTrimDuration || currentVideoDuration > this.maxTrimDuration) {
          return;
        }
        this.endHandlePos = mouseX;
        this.trimEnd = this.durationPositionRatio * mouseX <= this.videoDuration ? this.durationPositionRatio * (mouseX-this.handleWidth) : this.videoDuration;
        this.$emit('trim-end', this.trimEnd);
      } 

      
      else if (this.selectedElement === Handles.CENTER_HANDLE) {
        var mouseDirection = '';
        if(mouseX > this.centerX) {
          mouseDirection = "right";
        } 
        if(mouseX < this.centerX) {
          mouseDirection = "left";
        }

        if (this.startHandlePos <= 0+this.handleWidth && mouseDirection == "left"){
          return
        }

        if (this.endHandlePos >= this.canvasWidth-this.handleWidth && mouseDirection == "right"){
          return
        }
        
        this.startHandlePos = mouseX - this.distanceFromCenerToHandlers;
        this.endHandlePos = mouseX + this.distanceFromCenerToHandlers;


        this.trimStart = this.durationPositionRatio * ((mouseX - this.distanceFromCenerToHandlers) - this.handleWidth) > 0 ? this.durationPositionRatio * ((mouseX - this.distanceFromCenerToHandlers)-this.handleWidth) : 0;
        this.$emit('trim-start', this.trimStart);

        this.trimEnd = this.durationPositionRatio * (mouseX + this.distanceFromCenerToHandlers) <= this.videoDuration ? this.durationPositionRatio * ((mouseX + this.distanceFromCenerToHandlers)-this.handleWidth) : this.videoDuration;
        this.$emit('trim-end', this.trimEnd);
      } 

      // Make sure the handles stay within the slider track
      if (this.startHandlePos < 0+this.handleWidth) {
        this.startHandlePos = 0+this.handleWidth;
        
      }
      if (this.endHandlePos > this.canvasWidth-this.handleWidth) {
        this.endHandlePos = this.canvasWidth-this.handleWidth;
        

      }
      if (this.startHandlePos > this.endHandlePos) {
        this.startHandlePos = this.endHandlePos;
      }

      // Redraw the slider using requestAnimationFrame
      requestAnimationFrame(() => {
        this.drawSlider();
      });
    },
    handleMouseDown (event) {
      if(this.storedFrames.length == 0){
        return;
      }
      const mouseX = event.offsetX;
      const mouseY = event.offsetY;
      
      if (mouseX > (this.startHandlePos - this.handleWidth) && mouseX < this.startHandlePos ) {
        this.selectedElement = Handles.START_HANDLE;
      } 
      else if (mouseX > this.endHandlePos && mouseX < this.endHandlePos + this.handleWidth) {
        this.selectedElement = Handles.END_HANDLE;
      }

      else if (mouseX > (this.centerX-5) && mouseX < (this.centerX+5) && mouseY>0 && mouseY<10) {
        this.selectedElement = Handles.CENTER_HANDLE;
        this.distanceFromCenerToHandlers = (this.endHandlePos - this.startHandlePos) / 2;
        
      } 
      this.updateHandles(mouseX);
    },
    handleMouseMove (event) {
      if(this.storedFrames.length == 0){
        return;
      }
      if (this.selectedElement) {
        if (event.buttons === 1) {
          this.updateHandles(event.offsetX);
        }
      }
    },
    handleMouseUp () {
      this.selectedElement = null;
    },
    setCanvasWidth () {
      this.canvasWidth = this.$refs.sliderCanvas?.offsetWidth;
      this.frameWidth = (this.canvasWidth - (this.handleWidth * 2)) / this.frames.length;
    },
    updateGlobalCompositeOperation () {
      // Set the global composite operation to draw the white filter over the non-intersecting areas
      this.canvasContext.globalCompositeOperation = 'source-over';
      this.canvasContext.fillStyle = 'rgba(255, 255, 255, 0.8)';
      this.canvasContext.fillRect(
        0,
        0,
        this.startHandlePos,
        this.framesCanvas.height
      );
      this.canvasContext.fillRect(
        this.endHandlePos,
        0,
        this.canvasWidth - this.endHandlePos,
        this.framesCanvas.height
      );
    },
    handleDurationChange () {   
        this.endHandlePos = 60 * this.positionDurationRatio;
        this.trimEnd = 60;
        this.$emit('trim-end', this.trimEnd);
    },
  },
};
</script>

<style scoped>
  canvas {
    border-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: black;
    width: 100%;
    margin-bottom: 4px;
  }

</style>