<template>
  <b-container fluid>
  	<b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> Video Transcriber <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
    <b-row v-if="('items' in getCurrentSubscription)" no-gutters>
    	<b-col xs="12" md="6"  class="p-2">
	      <b-card border-variant="warning" text-variant="secondary" header-tag="header" class="bg-transparent text-center">
	      	<template #header>
	      		<div class="row">
	      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Current Subscription</p>
	      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bookmark-check-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-warning numbers"><b>{{getCurrentSubscription.items[0].price.name}}</b></b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">Status: <span class="text-warning">{{signInSubscriptionStatus}}</span></b-card-text>
	      </b-card>
	    </b-col>
	    <b-col xs="12" md="6" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Next Billing</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="calendar2-check-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-success numbers"><b>${{getCurrentSubscription.items[0].price.unit_price.amount/100}}</b> </b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">Date: <span class="text-success">{{getCurrentSubscription.next_billed_at.slice(0,19).replace('T', ' at ')}}</span></b-card-text>
	      </b-card>
	    </b-col>
		</b-row>

		<b-row v-if="('items' in getCurrentSubscription)" no-gutters>
    	<b-col xs="12" md="6"  class="p-2">
    		<b-alert v-if="getChangeSubStatus[0]" :variant="getChangeSubStatus[2]"  show dismissible>{{ getChangeSubStatus[1] }}</b-alert>
	      <b-card  text-variant="secondary" header-tag="header" class="bg-dark text-left">
	      	<template #header>
	      		<div class="row">
	      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Change My Subscription</p>
	      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bookmarks-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>


	      	<b-card-text v-if="(signInSubscriptionStatus == 'trialing' || signInSubscriptionStatus == 'past_due')" text-tag="p" class="mb-2">Note: Trialing and Past-due subscriptions can not be updated! Make the past due payment or <a href="https://speechor.com/Askus" target="_blank">contact us</a> to help you updating the current trialing subscription</b-card-text>

	      	<b-card-text v-if="(signInSubscriptionStatus == 'active')" text-tag="p" class="">Select the pack you want to switch to, from the following available packages and plans (<a href="https://speechor.com/Subscription_Video_Transcriber" target="_blank">Plans details</a>)</b-card-text>
	      	
	      	<div v-if="(signInSubscriptionStatus == 'active')" class="mt-2 mb-2 text-secondary">
                  <b-form-group label="Available Packages">
                    <b-form-select
                          class="w-100 mt-0"
                          size="sm"
                          id="availableprices"
                          v-model="selectedPrice"
                          :options="getPriceOptions"
                          required
                          @input="getSelectedOptionText"
                          >
                          <template #first>
                            <b-form-select-option value="" disabled>--Select a package--</b-form-select-option>
                          </template>
                    </b-form-select>
                    </b-form-group>
          </div>
          <b-card-text v-if="(signInSubscriptionStatus == 'active')" text-tag="p" class="mb-0"><b-button @click="$bvModal.show('changeConfirm')"  :disabled="(selectedPrice=='')" variant="primary" size='md'>Change Subscripltion Plan</b-button></b-card-text>


	      </b-card>
	    </b-col>
	    <b-col  xs="12" md="6" class="p-2">
	      <b-card v-if="(signInSubscriptionStatus == 'past_due')"  text-variant="secondary" class="bg-dark text-left" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Manage payment method</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="credit-card2-back-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="">To change your payment method click the button bellow</b-card-text>
	      	<b-card-text text-tag="p" class="mb-0"><a :href="getCurrentSubscription.management_urls.update_payment_method" target="_blank"><b-button size='sm'>Manage Payment Method <b-icon icon="box-arrow-up-right" variant="light" font-scale="0.8" aria-hidden="true"></b-icon></b-button></a></b-card-text>
	      </b-card>
	    </b-col>
		</b-row>

		<!-- Info modal: change subs audio? -->
    <b-modal
      id="changeConfirm"
      title="Change Subscription Plan - Video Transcriber "
      @ok="onChangeSubscription()"
      ok-title="I Confirm Change Now"
      size="md"
      button-size="md"
      no-close-on-esc 
      no-close-on-backdrop >
        <div style="text-align: center;">
              <p>You are changing your subscription to "{{selectedPriceName}}" plan, Do You Confirm?</p>
              <b-icon icon="exclamation-triangle" font-scale="2" animation="throb" variant="warning"></b-icon>
        </div>
    </b-modal>
    <!-- /Info modal: change subs audio? -->
  	
  </b-container>
</template>

<script>
	import { mapGetters } from "vuex";
export default {
	data() {
	    return {
	    	selectedPrice: "",
	    	selectedPriceName: "",
		    };
	},
    computed: {
			...mapGetters(["getCurrentSubscription", "getChangeSubStatus"]),

			signInSubscriptionStatus(){
				return this.$store.state.videoSubStatus;
			},

			getPriceOptions(){
	     	let mappedPricesJson = [];

	     	const currentPriceName = this.$store.state.currentSubscription.items[0].price.name;

	     	const regex = /\s+/g;
	     
	    	if ('items' in this.$store.state.currentSubscription && this.$store.state.paddleAllRegularPrices.length>0){

	    		let filteredPrices = [];
	    		//if current price is not  trial is Regular, thing about it
	    		if (currentPriceName.includes('Regular')){
	    			filteredPrices =  this.$store.state.paddleAllRegularPrices.filter(obj => (obj.name.replace(regex, '').toLowerCase() !== currentPriceName.replace(regex, '').toLowerCase()));
	    		
	    		}
	    		//if current price is with trial
	    		if (!currentPriceName.includes('Regular')){
	    			filteredPrices =  this.$store.state.paddleAllRegularPrices.filter(obj => (obj.name.replace('Regular', '').replace(regex, '').toLowerCase() !== currentPriceName.replace(regex, '').toLowerCase()));
	    		} 

	    		mappedPricesJson = filteredPrices.map(singleObj => {
	          const rObj = {};
	          rObj.text = singleObj.name.replace('Regular','');
	          rObj.value = singleObj.id;
	          return rObj;
	          });

	    		
	    	}
	    	return mappedPricesJson;
      },
    },
    
    mounted() {
    	this.$store.dispatch("updateVideoSubscriptionStatus");
    	this.$store.dispatch("getPaddlePrices", {router: this.$router, moduleReference: "Transcriber"});
      this.$store.dispatch("getCurrentSubscription", {router: this.$router});
       
    },

    methods:{
    	getSelectedOptionText(){
    		let sel = document.getElementById("availableprices");
    		this.selectedPriceName = sel.options[sel.selectedIndex].text;
    	},
    	onChangeSubscription(){
    		if (this.selectedPrice !== ''){
    			this.$store.dispatch("changeSubscription", {router: this.$router,  priceId : this.selectedPrice});
    		}
    	}
    }
};
</script>


<style scoped>
	.icon-color {
  	color: #BFC9CA;
  	opacity: 0.1;
  }
  .btn .b-icon.bi {
  	vertical-align: text-top;
  }
</style>