<template>
    <label>
        <input
            :id="inputId"
            type="color"
            v-model="colorpicker"
            class="color-picker"
            @input="onColorInput"
            :disabled="disabledColor"
        >{{label}}
    </label>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Choose a color"
        },
        value: {
            type: String,
            default: "#ffffff"
        },
        coloredItem: {
            type: String,
            default: "fontColor"
        },
        disabledColor: {
            type: Boolean,
            default: false
        }

    },

    data() {
    return {

        colorpicker: null,
        inputId: null,

        }
    },

    mounted(){
        this.colorpicker = this.value;
        this.inputId = this.id;
    },

    methods: {
        onColorInput(){
            this.$emit('color-changed', {"color": this.colorpicker, "item": this.coloredItem });
        }
    }

    //
}
</script>

<style scoped>
label {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.color-picker {
  margin-right: 2px;
  
  width: 26px;
  height: 30px;
  padding: 0;
  border: none;
  background: transparent;
}
</style>