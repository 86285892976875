<template>
  <div style="width: 100%; height: auto; overflow: hidden;">
    <video id="videoshow" v-show="storedFrames.length>0" src="" style="width: 100%; pointer-events: none;" controls></video>
    <img class="mb-1" style="width: 100%; height: auto; overflow: hidden;" v-show="storedFrames.length==0" src="https://speechormanager.univerhome.com/uploads/media/transcriber_thimb.jpg" alt="Your input video will show here">
    <div style="text-align:center;">
      <b-button 
          class="mr-1"
          v-b-tooltip.bottom title="replay from start"
          size="sm"
          squared 
          id="restartButton"
          variant="secondary"
          disabled
          > |► </b-button>
      <b-button 
          v-b-tooltip.bottom title="play/pause"
          size="sm"
          squared 
          id="toggleButton"
          variant="secondary"
          disabled
          > ► </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  data () {
    return {
      player: null,
      canvas: null,
      context: null,
      numFrames: 10,
     
    };
  },

  computed: {
    storedFrames(){
      return this.$store.state.frames;
    }
  },
 
  methods: {
    
    extractFrames () {
      // Create a new video element
      const video = document.createElement('video');
      video.crossOrigin = 'anonymous';
      video.volume = 0;
      video.src = this.player.src;
      video.play();

      // Handle the 'durationchange' event
      const handleDurationChange = () => {
        const duration = video.duration;
        const interval = duration / this.numFrames;
        let currentTime = 0;
        const frames = [];

        // Create a canvas element to draw the frames on
        const actualVideoHeight = video.videoHeight;
        const actualVideoWidth = video.videoWidth;
        const thumbnailWidth = 200;
        const thumbnailHeight = (thumbnailWidth / actualVideoWidth) * actualVideoHeight;
        this.canvas.width = thumbnailWidth;
        this.canvas.height = thumbnailHeight;

        // Extract frames from the video
        const extractFrame = () => {
          if (currentTime > duration || frames.length === this.numFrames) {
            // Set the frames in the store
            this.$store.dispatch("saveFrames", {frames: frames});
            this.$store.dispatch("framesLoading", {status: "loaded", message: "Video uploaded!\nJust close this window"});
            //console.log("loaded");
            //console.log(frames);
            
            video.currentTime = 0;
            cleaup();
            return;
          }
          currentTime += interval;
          video.currentTime = currentTime;
          this.context.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);
          const dataUrl = this.canvas.toDataURL();
          frames.push(dataUrl);
         
          this.$store.dispatch("framesLoading", {status: "inprogress", message: "Uploading the Video\nIt may take a few seconds Please wait..."});
          video.requestVideoFrameCallback(extractFrame);
        };

        if("requestVideoFrameCallback" in HTMLVideoElement.prototype){
            video.requestVideoFrameCallback(extractFrame);
          } else {
            this.$store.dispatch("framesLoading", {status: "error", message: "Error: This browser doses not support some Speechor tools please change your browser! \nJust close this window"});
          }
        
        video.currentTime = 0;

      };

      video.addEventListener('durationchange', handleDurationChange);

      const cleaup = () => {
        video.pause();
        video.removeEventListener('durationchange', handleDurationChange);
        video.remove;
        

      }
    },

  },
  mounted () {
    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d');

    this.player = document.getElementById("videoshow");
    

    this.player.onloadedmetadata = () => {
      //console.log(event.target.duration)
      //this.$store.dispatch("videoDuration", {duration: event.target.duration});
      this.extractFrames();
    }
    
  },
};
</script>

<style scoped>
 
  
</style>