<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> Video Transcriber <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Video Transcriber Module - Quick start tutorial</h4>
          </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-embed 
          type="video" 
          aspect="16by9" 
          controls 
          poster="https://speechormanager.univerhome.com/uploads/media/transcriber_thimb.jpg">
            <source src="https://speechormanager.univerhome.com/uploads/media/transcriber-tuto.mp4" type="video/mp4">
          </b-embed>
          
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Important recommendations:</h4>
          <p><span style="color:green;font-weight:bold">&#10004;</span> Respect the required input video format (mp4 files only), the Max size (200MB), the Min duration (60 seconds) the Max duration (30 Minutes). We recommend 1280x720 (16:9) input videos to get high quality output, lower resolutions produce lower quality.</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> Respect the input Images format (png, jpg, jpeg files only), the Max size (3MB) for each image. Min width 500px and Min height 500px.</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> Respect the Image (logo) format (png, jpg, jpeg and gif files only), the Max size (1MB).</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> Respect the background music file format (mp3 files only), the Max size (20MB), Min music duration 90 seconds.</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> When using a video as input: Select the part you want to transcribe using the sliders under the video  as shown in the video above, you can play the selected part by clicking the play/pause button or replay button. If you want to select 60s or 90s click the corresponding button.</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> When using images as input: Select the video duration 60s or 90s. Upload multiple images at once (Note: put your images at the same location before upload), the min is 3 images the max is 5 images. Select an image if you want to reorder it in the list, images will be added to the video as ordered from top to bottom. </p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> About the Ai Voice-over section: In case of video input this part is optional, the software will use the original audio, if you enable the Ai Voice-over section then the original audio will not be used, instead the Ai generated Voiceover will be added to the video). In case of images Ai Voice-over is required. Paste or generate your script using the integrated Ai tool, then Select a language and a voice from the available ones(the language selected should be the same selected in the Settings section). You can add a background music or keep it empty (it's optional)</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span>About the Settings section: Select the main language of your video. Select a text font or keep the default one. Format the text and the output video using the available setting controls.</p>

          <p><span style="color:green;font-weight:bold">&#10004;</span> The preview section will only show a random frame from your input video or one image from images, and display a random text to preview your pre-settings. The output video quality will be way better than this preview.</p>
          <p><span style="color:green;font-weight:bold">&#10004;</span> After clicking generate your video data will be uploaded to our Ai server (it will take few seconds or few minutes depending of the Ai server queue). Once generated, your video will appears in the table bellow your UI (refresh the table data from the refresh button). Please download your videos into your device to avoid loosing them, the generated videos are removed from our server after 6 hours, no backup available.</p>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  P {
    color: white;
  }
</style>